import React from 'react';

// MUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CheckboxIcon from '@mui/icons-material/Done';

export default function CheckboxList({ name, options, onSelection }) {

  const handleToggle = (option) => {
    option.selected = !option.selected
    onSelection(name, option)
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {options.map((option) => {
        option.selected = (option.selected === undefined) ? false : option.selected
        return (
          <ListItem key={option.name} disablePadding disableGutters>
            <ListItemButton onClick={() => handleToggle(option)} selected={option.selected}>
              <ListItemText color="primary" id={`checkbox-list-label-${option.name}`} primary={option.title || option.name} />
              <ListItemIcon sx={{ pr: 0, minWidth: 42 }}>
                <Checkbox
                  checked={option.selected}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${option.name}` }}
                  icon={<div style={{ height: '24px', width: '24px' }}></div>}
                  checkedIcon={<CheckboxIcon />}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  );
}
