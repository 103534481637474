import React from 'react';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Custom UI
import ActionBar from '../../../components/ActionBar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FiltersModal({ children, title, open, actions, handleClose }) {

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ sx: { width: "90vw", maxWidth: "600px" } }}
    >
      <Stack sx={{ height: '90vh' }}>
        <AppBar color="secondary" position="sticky">
          <Toolbar>
            <Typography sx={{ flex: 1 }} component="h2" variant="h1">{title}</Typography>
            <IconButton
              edge="start"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ flex: 1, overflow: 'auto' }} className={'sp-scroller'}>
          { children }
        </Box>
        <DialogActions style={{ position: 'sticky', bottom: '0'}}>
          <Box width="100%">
            <ActionBar actions={actions} position="sticky" />
          </Box>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
