import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import { TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

// Helpers
import { Controller, FormProvider, useForm } from 'react-hook-form';

export default function AddressForm({ onSubmit, loading = false, defaultValues = {} }) {

  const { t } = useTranslation();
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues
  });

  return (
    <>
      <Typography variant="h2" sx={{mb: 2}}>{t("page_account_update_address.text_title")}</Typography>
      <Paper sx={{ p: 2 }}>
        <FormProvider>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1}>
              <Controller
                name="company"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  type="text"
                  placeholder={t("page_account_update_address.label_company")}
                  error={!!errors.address1}
                />}
              />
              <Controller
                name="address1"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  required={true}
                  type="text"
                  placeholder={t("page_account_update_address.label_address1")}
                  error={!!errors.address1}
                />}
              />
              <Controller
                name="address2"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  type="text"
                  placeholder={t("page_account_update_address.label_address2")}
                  error={!!errors.address2}
                />}
              />
              <Controller
                name="city"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  required={true}
                  type="text"
                  placeholder={t("page_account_update_address.label_city")}
                  error={!!errors.city}
                />}
              />
              <Controller
                name="province"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  required={true}
                  type="text"
                  placeholder={t("page_account_update_address.label_region")}
                  error={!!errors.province}
                />}
              />
              <Controller
                name="country"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  required={true}
                  type="text"
                  placeholder={t("page_account_update_address.label_country")}
                  error={!!errors.country}
                />}
              />
              <Controller
                name="zip"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  required={true}
                  type="text"
                  placeholder={t("page_account_update_address.label_postcode")}
                  error={!!errors.zip}
                />}
              />
              <Box sx={{pt:2}}>
                <LoadingButton loading={loading} type="submit" variant="contained">{t("page_account_update_address.btn_save")}</LoadingButton>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </Paper>
    </>
  )
}