import React from 'react';
import { useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Custom UI
import PageTitle from '../../components/PageTitle';

// Custom UI Account Components
import AddressForm from './components/AddressForm';
import NameForm from './components/NameForm';
import PhoneNumberForm from './components/PhoneNumberForm';

// Helpers
import useFetch from 'use-http';

// Context
import { useAuth } from '../../context/AuthContext';

const Item = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
}));

export default function MyAccount() {

  const { t } = useTranslation();

  const auth = useAuth()

  const { put, response, loading } = useFetch();

  const handleNameSave = async (name) => {
    console.log('Save name', name)
    const user = await put('customers', name)
    console.log('User', user)
    auth.updateUser(user)
  }

  const handlePhoneSave = async (phone) => {
    console.log('Save phone number', phone)
    const user = await put('customers', phone)
    console.log('User', user)
    auth.updateUser(user)
  }

  const handleAddressSave = async (address) => {
    console.log('Address save', address)
    const user = await put('customers', {
      defaultAddress: address
    })
    console.log('User', user)
    auth.updateUser(user)
  }

  console.log('My account user', auth.user)
  return (
    <Container sx={{ p: 2 }}>
      <Stack spacing={2}>
        <PageTitle>{t("page_account_update.text_title")}</PageTitle>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Box sx={{ alignItems: 'center', textAlign: "center" }}>
            <Typography variant="body1_bold" align="center" sx={{ textAlign: 'center' }}>
              {auth.user.first_name} {auth.user.last_name}
            </Typography>
            <Typography variant="body1">
              {auth.user.email}
            </Typography>
          </Box>
        </Stack>
        <Item>
          <NameForm loading={loading} onSubmit={handleNameSave} defaultValues={{ first_name: auth.user.first_name || '', last_name: auth.user.last_name || '' }} />
        </Item>
        <Item>
          <PhoneNumberForm loading={loading} onSubmit={handlePhoneSave} defaultValues={{ phone: auth.user.phone || '' }} />
        </Item>
        <Item>
          <AddressForm loading={loading} onSubmit={handleAddressSave} defaultValues={auth.user.defaultAddress} />
        </Item>
      </Stack>
    </Container>
  );
}
