import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

// Custom UI
import TIUSelect from '../../../components/Select';
import TIUTextField from '../../../components/TextField';
import ProductCard from './ProductCard';

export default function ProductSwapDetails({ product, control, errors }) {

  let sizes
  if (product.variants) {
    sizes = product.variants.map(v => ({
      id: v.id,
      title: v.name
    }))
  } else {
    sizes = product.variant
  }

  console.log('Product', product)

  return (
    <Container maxWidth="md" disableGutters sx={{ mt: 2 }}>
      <Stack spacing={4} sx={{ pt: 2, pb: 2 }}>
        test
        <ProductCard product={product} />
        <TIUSelect control={control} errors={errors} required={true} name="variant_id" label="Item size" placeholder="What size is the item" options={sizes} defaultValue={product.customer_id ? product.variant.id : ''} />
        <TIUTextField control={control} errors={errors} required={false} name="notes" label="Additional information (optional)" placeholder="Add some information about the item" multiline={true} defaultValue={product.notes} />
      </Stack>
    </Container>
  )
}
