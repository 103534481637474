import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Context
import { useAuth } from '../context/AuthContext';
import { LoadingProgressBar } from '../context/LoadingContext';

// MUI
import Container from '@mui/material/Container';

// Custom UI
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';


// Pages
import HowItWorksPage from '../pages/how/HowItWorks';
import LandingPage from '../pages/landing/LandingPage';

export default function Main({ match }) {

  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/app/welcome" />
  }

  return (
    <>
      <Navigation />
      <LoadingProgressBar />
      <Container maxWidth="xl" disableGutters>
        <Switch>
          <Route path={`${match.url}/how-it-works`} render={(props) => (<HowItWorksPage {...props} />)} />
          <Route path={`${match.url}/`} render={(props) => (<LandingPage {...props} />)} />
        </Switch>
      </Container>
      <Footer />
    </>
  );
}


