import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// Custom UI
import ActionBar from '../../components/ActionBar';
import ProductCard from '../products/components/ProductCard';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';

// Helpers
import useFetch from 'use-http';

export default function CustomerProductsConfirmPage({ match, type }) {

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const [product, setProduct] = useState();

  const { post, put, loading, response } = useFetch('customer-products')

  const listProduct = async (product) => {
    let newProduct;

    if (!product.customer_id) {
      const payload = {
        ...product,
        product_id: product.id,
        variant_id: product.variant.id,
        is_listed: true,
      }
      console.log("[CustomerProductConfirm]: listProduct", payload);
      newProduct = await post(payload)
    } else {
      const payload = {
        ...product,
        variant_id: product.variant.id,
        is_listed: true
      }
      console.log("[CustomerProductConfirm]: listProduct", payload);
      newProduct = await put(product.id, payload)
    }

    if (response.ok) {
      console.log('[CustomerProductConfirm]: Product listed successfully')
    }

    return newProduct;
  }

  useEffect(() => {
    if (location.state?.customerProduct) {
      console.log("[CustomerProductsConfirmPage] product from location:", location.state.customerProduct);
      setProduct(location.state.customerProduct)
    }
  }, [setProduct, location])

  let filters = new URLSearchParams({ hideMine: true })
  if (location.state?.swapSize && location.state?.swapSize.length > 0) {
    filters.append('filters', JSON.stringify([{name: 'variant.name.keyword', values: location.state?.swapSize}]))
  }

  const actions = [
    {
      primary: true,
      title: t('page_create_confirm.btn_confirm'),
      loading,
      onClick: async () => {
        const newProduct = await listProduct(product)
        history.push({
          pathname: '/app/swaps/search/page/1',
          search: filters.toString(),
          state: { offeredProduct: newProduct, viaNewProductListed: true }
        })
      }
    }, {
      title:  t('page_create_confirm.btn_cancel'),
      onClick: () => {
        history.replace({
          pathname: './list'
        })
      }
    },
  ]

  return (
    <>
      {product && (

        <PageContainer>

          <PageTitle>
            {t('page_create_confirm.text_title')}
          </PageTitle>

          <Typography variant="h2">{t('page_create_confirm.text_title_customer_item')}</Typography>

          <Paper sx={{ mb: 6, mt: 2, p: 2 }} elevation={3}>
            <ProductCard product={product} />
          </Paper>
        </PageContainer>
      )}
      <ActionBar direction="column" actions={actions} />
    </>
  );
}
