import React, { useState, useEffect } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';

// Custom UI
import Alert from './Alert';

// Assets
import './Care.scss';

export default function Care({product}) {

  const { t } = useTranslation();
  const [careOptions, setCareOptions] = useState([]);

  useEffect(() => {
    const filteredMetafield = product.metafields.find(metafield => metafield.key === 'care_options');
    if (!filteredMetafield) {
      return;
    }
    const careOptions = filteredMetafield.value?.split("|");
    const opts = careOptions?.map((option, index) => {
      const opt = option?.split(',');
      if (opt?.length === 2 && opt[0] && opt[1]) {
        return {
          id: index,
          key: opt[0],
          title: opt[1],
        };
      }
    })?.filter(Boolean); // Filters out undefined values
    setCareOptions(opts);
  }, []);

  return (
    <>
      <div className="care">
        { careOptions.map((option, index) => {
          return (
            <div key={index} className="care__option">
              <img src={`/images/care/icon-${option.key}.svg`} className="care__icon" />
              <Typography variant='body1'>{option.title}</Typography>
            </div>
          )
        })}
      </div>
      { careOptions.length === 0 &&
        <Alert severity="warning"><Typography variant='body1'>{t('pdp_details.accordion_text_care_missing')}</Typography></Alert>
      }
    </>
  );

}
