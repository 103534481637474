import React, { useState, useImperativeHandle, forwardRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Custom UI
import CheckboxList from '../../../components/CheckboxList';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';

// i18n
import { useTranslation } from 'react-i18next';

// Helpers
import { useFetch } from 'use-http';
import useMappedValues from '../../../hooks/MappedValues';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ProductFilters ({ onFilterChange, onFilterReset, type }, ref) {

  const { t } = useTranslation();
  const location = useLocation();
  const { get } = useMappedValues();

  const urlSearchParams = new URLSearchParams(location.search);
  const filterFromQuery = urlSearchParams.get('filters');

  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const filterValueFromQuery = filterFromQuery ? JSON.parse(decodeURIComponent(filterFromQuery)) : [];

  const translateOptions = ['condition'];

  const { post, response, loading } = useFetch();

  const handleResetFilters = () => {
    onFilterReset();
  }

  const getFilters = useCallback(() => {
    return post(`${type}/search`, {
      page: 1,
      limit: 1
    })
  }, [post, filters])

  useEffect(() => {
    async function createFilters() {
      const { filters = [] } = await getFilters() || {};
      if (response.ok) {

        // Sort filters alphabetically
        if (filters && filters.length > 0) {
          filters.forEach(filter => {

            const filterName = filter.name.split('.')[0];

            filter.options.sort((a, b) => {
              const options = { numeric: true, sensitivity: 'base' };
              return a.name.localeCompare(b.name, undefined, options);
            });

            if (translateOptions.includes(filterName)) {
              filter.options.forEach(o => {
                o.title = t(get(filterName, o.name))
              })
            }
          });
        }

        // Restore visual state of filters
        filters.forEach( filter => {

           filterValueFromQuery.forEach( filterQS => {
              if (filter.name === filterQS.name) {
                filter.options.forEach( option => {
                  if (filterQS.values.includes(option.name)) {
                    option.selected = true;
                  }
                })
              }
           })
        });

        // Recreate the selectedFilters object
        const s = {};
        for (const { name, values } of filterValueFromQuery) {
          s[name] = values;
        }

        setSelectedFilters(s);
        setFilters(filters);

      }
    }
    createFilters();
  },[]);

  const filterLabel = (key) => {
    const parts = key.split(".");
    const name = parts[0];

    if (name==="variant") {
      return "Size";
    }

    return name;
  }

  const handleFilterSelection = (name, option) => {

    if (option.selected) {
      if (!selectedFilters[name]) {
        selectedFilters[name] = [option.name];
      } else {
        selectedFilters[name].push(option.name);
      }
    } else {
      selectedFilters[name] = selectedFilters[name].filter(value => value !== option.name);
      if (selectedFilters[name].length === 0) {
        delete selectedFilters[name]
      }
    }

    setSelectedFilters(selectedFilters);
    onFilterChange(selectedFilters);
  }

  useImperativeHandle(ref, () => ({
    resetFilters: handleResetFilters
  }))

  return (
    <Box sx={{ mt: 1}}>
      { loading && <Spinner />}
      { filters && (filters.length > 0) && filters.map((filter) => (
        <Accordion key={`section_${filter.name}`} sx={{ ml: 1}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ p: 1 }} />} aria-controls={`${filter.name}-content`} id={`${filter.name}-header`}>
            <Typography component="h2" variant="h2" sx={{ textTransform: 'capitalize' }}>{filterLabel(filter.name)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxList name={filter.name} options={filter.options} onSelection={handleFilterSelection} />
          </AccordionDetails>
        </Accordion>
      ))}

      { !filters && !filters.length && (
        <Alert severity="Info" message="{t('search.text_no_filters')}" />
      )}
    </Box>
  );
}

export default forwardRef(ProductFilters);
