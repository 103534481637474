import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MUI
import Stack from '@mui/material/Stack';

// Custom UI
import ProductsSearch from '../products/components/ProductsSearch';

export default function SwapSearchPage({ title, type, filter }) {

  const history = useHistory()
  const location = useLocation()

  const [offeredProduct, setOfferedProduct] = useState();

  useEffect(() => {
    console.log('Success product', location.state)
    if (location.state?.offeredProduct) {
      setOfferedProduct(location.state.offeredProduct)
    }
  }, [setOfferedProduct, location.state])

  const handleSelectProduct = (selectedProduct) => {
    console.log('Selected product', selectedProduct)
    if (selectedProduct?.is_offered) {
      history.push({
        pathname: `/app/offers/${selectedProduct.offer_id}`
      })
    } else {
      history.push({
        pathname: `../../view/${selectedProduct.id}`,
        state: { offeredProduct, wantedProduct: selectedProduct }
      })
    }
  }

  return (
    <Stack>
      <ProductsSearch type="swap-products" hideMine={!!offeredProduct} filter={filter} title={title} showRequestSwapButton={true} onProductClick={handleSelectProduct} />
    </Stack>
  );
}
