import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// MUI

// Custom UI
import ProductCreateDetails from '../products/components/ProductCreateDetails'
import ActionBar from '../../components/ActionBar';
import PageContainer from '../../components/PageContainer';

// Helpers
import { useFetch } from 'use-http';
import { FormProvider, useForm } from 'react-hook-form';

export default function CustomerProductsCreatePage({ type }) {

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [product, setProduct] = useState();

  const [wantedProduct, setWantedProduct] = useState();

  useEffect(() => {
    if (location.state?.wantedProduct) {
      setWantedProduct(location.state.wantedProduct);
    }
  }, [setWantedProduct, location]);

  useEffect(() => {
    if (location.state?.product) {
      setProduct(location.state.product);
    }
  }, [setProduct, location])

  const { get, put, response } = useFetch('customer-products');

  const { handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    console.log('Product data', data)

    const customerProduct = await put(product.id, {
      product_id: product.id,
      is_listed: true,
      ...data
    })

    if (response.ok) {
      console.log('Updated customer product', customerProduct)
      history.push({
        pathname: 'request',
        state: { wantedProduct, offeredProduct: customerProduct }
      })
    }
  }

  const actions = [
    {
      title: 'Back',
      onClick: () => {
        history.goBack()
      }
    }, {
      primary: true,
      title: 'Choose Item',
      disabled: !isValid,
      submit: true
    }
  ]

  useEffect(() => {
    async function initializeProducts() {

      if (!product && params.productId) {
        const product = await get(params.productId);
        if (response.ok) {
          console.log('Fetched product', product)
          setProduct(product);
        }
      }
    }

    initializeProducts()
  }, [get, response, product, params.productId])

  return (
    <PageContainer>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          {product && <ProductCreateDetails product={product} control={control} errors={errors} />}
          <ActionBar actions={actions} />
        </form>
      </FormProvider>
    </PageContainer>
  );
}
