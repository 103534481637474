
// The translation strings all of these map to can be
// found in/public/locales/en/translation.json

const mappedValues = {
  filters: {
    "variant.name": "size",
    "variants.name": "size",
  },
  reason: {
    reason_1: 'product_reason.reason_1',
    reason_2: 'product_reason.reason_2',
    reason_3: 'product_reason.reason_3',
    reason_4: 'product_reason.reason_4',
    reason_5: 'product_reason.reason_5',
    reason_6: 'product_reason.reason_6',
    reason_7: 'product_reason.reason_7',
  },
  condition: {
    condition_1: 'product_condition.condition_1',
    condition_2: 'product_condition.condition_2',
    condition_3: 'product_condition.condition_3',
    condition_4: 'product_condition.condition_4',
    condition_5: 'product_condition.condition_5',
  },
};

export function get(path, key) {
  return mappedValues[path][key] || key;
}

export default function useMappedValues() {

  // To avoid excessive re-renders of the component. We return the string ID of
  // the translation which is then used by the caller to get the actual translation.

  // You access the hook like this:
  // const { get } = useMappedValues();
  // <Typography>{t(get('conditions', product.condition))}</Typography>

  return {
    mappedValues,
    get,
  };
}
