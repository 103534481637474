import React from 'react';

// MUI
import Paper from '@mui/material/Paper';
import { TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

// Helpers
import { Controller } from 'react-hook-form';

export default function AddressForm({ title = 'Address', keyName = 'address', address = {}, control, errors }) {

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={1}>
        <Typography variant="h2">{ title }</Typography>
        <Controller
          name={`${keyName}.name`}
          control={control}
          defaultValue={address.name || ''}
          rules={{
            required: true
          }}
          render={({ field }) => <TextField
            {...field}
            type="text"
            required={true}
            placeholder="Name"
            error={!!errors.name}
          />}
        />
        <Controller
          name={`${keyName}.company`}
          control={control}
          defaultValue={address.company || ''}
          rules={{
            required: false
          }}
          render={({ field }) => <TextField
            {...field}
            type="text"
            placeholder="Company"
            error={!!errors.company}
          />}
        />
        <Controller
          name={`${keyName}.address1`}
          control={control}
          defaultValue={address.address1 || ''}
          rules={{
            required: true
          }}
          render={({ field }) => <TextField
            {...field}
            required={true}
            type="text"
            placeholder="Address 1"
            error={!!errors?.address1}
          />}
        />
        <Controller
          name={`${keyName}.address2`}
          control={control}
          defaultValue={address.address2 || ''}
          rules={{
            required: false
          }}
          render={({ field }) => <TextField
            {...field}
            type="text"
            placeholder="Address 2"
            error={!!errors?.address2}
          />}
        />
        <Controller
          name={`${keyName}.city`}
          control={control}
          defaultValue={address.city || ''}
          rules={{
            required: true
          }}
          render={({ field }) => <TextField
            {...field}
            required={true}
            type="text"
            placeholder="City"
            error={!!errors?.city}
          />}
        />
        <Controller
          name={`${keyName}.province`}
          control={control}
          defaultValue={address.province || ''}
          rules={{
            required: false
          }}
          render={({ field }) => <TextField
            {...field}
            required={false}
            type="text"
            placeholder="Region"
            error={!!errors?.province}
          />}
        />
        <Controller
          name={`${keyName}.zip`}
          control={control}
          defaultValue={address.zip || ''}
          rules={{
            required: true
          }}
          render={({ field }) => <TextField
            {...field}
            required={true}
            type="text"
            placeholder="Postcode"
            error={!!errors?.zip}
          />}
        />
      </Stack>
    </Paper>
  )
}