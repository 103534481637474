import React from "react";

// MUI
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Helpers
import { Controller } from "react-hook-form";

const TIUSelect = ({
  placeholder,
  control,
  options,
  name,
  label,
  required = false,
  disabled = false,
  defaultValue = "",
}) => {
  return (
    <FormControl>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        id={name}
        name={name}
        control={control}
        rules={{
          required,
        }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            labelId={`${name}-label`}
            label={label}
            {...field}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default TIUSelect;
