import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default function ProductRow({ product, actions }) {

  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <img
          src={`${product.images[0]}?crop=center&height=200`}
          style={{ height: 138, width: 92 }}
          alt={product.name}
        />
        <Box>
          <Typography component="p" variant="p">{product.name}</Typography>
          <Typography component="p" variant="body1_bold" sx={{ mt: 1 }} >{t("product_row.label_size")} {product.variant.name}</Typography>
          <Box sx={{ pt: 3 }}>
            <Stack spacing={2} direction="row" sx={{ mt: 2}}>
              { actions }
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Divider />
    </>
  );
}
