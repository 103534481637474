import './App.scss';
import React, { useEffect } from 'react';
import { ThemeProvider } from './theme/ThemeContext';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Provider as HttpProvider } from 'use-http';

// Tracking
import TagManager from 'react-gtm-module';

// Layouts
import AppLayout from './layouts/AppLayout';
import AuthLayout from './layouts/AuthLayout';
import LandingLayout from './layouts/LandingLayout';

// Modals
import DialogProvider from './dialog/DialogProvider';

// Helpers
import PrivateRoute from './helpers/PrivateRoute';

// Contexts
import { useAuth } from './context/AuthContext';
import { useLoading } from './context/LoadingContext';

// Custom UI
import ThemePicker from './theme/ThemePicker';

const hostname = window.location.hostname;
const endpoint = import.meta.env.VITE_SWAP_API || `https://swap-api.${hostname.split('.').slice(1).join('.')}`

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_SWAP_SHOP_GTM_ID
}

TagManager.initialize(tagManagerArgs);

const config = {
  api_endpoint: endpoint
};

function App() {

  const { token, refreshToken, isAuthenticated, getUser } = useAuth()
  const { setIsLoading } = useLoading();

  const options = {
    cachePolicy: 'no-cache',
    headers: {
      Authorization: `Bearer ${token}`
    },
    interceptors: {
      request: async ({ options }) => {
        setIsLoading(true)
        return options
      },
      response: async ({ response }) => {
        if (!response.ok) {
          console.error('Response Error:', response);
          if (response.status === 401) {
            await refreshToken();
          }
        }
        setIsLoading(false);
        return response;
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, []);

  return (
    <HttpProvider url={config.api_endpoint} options={options}>
      <ThemeProvider>
        <DialogProvider>
          <Router>
            <Switch>
              <Route path="/landing" component={LandingLayout} />
              <Route path="/auth" component={AuthLayout} />
              <PrivateRoute path="/app" component={AppLayout} />
              <Route path="/" exact>
                { isAuthenticated ? <Redirect to="/app/welcome" /> : <Redirect to="/landing" /> }
              </Route>
              <Route path='*' component={LandingLayout} />
            </Switch>
          </Router>
          <ThemePicker />
        </DialogProvider>
      </ThemeProvider>
    </HttpProvider>
  );
}

export default App;
