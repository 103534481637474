import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { useApp } from '../../context/AppContext';

// MUI
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'

// Custom UI
import ActionBar from '../../components/ActionBar';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import Progress from '../../components/Progress';

// Helpers
import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";

const LeftImage = styled(Paper)`
  position: relative;
  top: 12px;
  left: 2%;
  width: 160px;
  height: 240px;
  margin: 39px 181px 31px 16px;
  transform: rotate(-6deg);
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border: solid 8px #fff;
  overflow: hidden;
`

const CenterImage = styled(Paper)`
  position: absolute;
  top: 60px;
  left: 30%;
  width: 160px;
  height: 240px;
  transform: rotate(0deg);
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border: solid 8px #fff;
  overflow: hidden;
`

const RightImage = styled(Paper)`
  position: absolute;
  top: 80px;
  right: 10%;
  width: 160px;
  height: 240px;
  transform: rotate(9deg);
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border: solid 8px #fff;
  overflow: hidden;
`

export default function ItemsPage() {

  const { t } = useTranslation();
  const { setProgression, setShowProgression } = useApp();

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [product, setProduct] = useState();

  const particlesInit = useCallback(async (engine) => {
    await loadConfettiPreset(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const particleConfig = {
    particles: {
      color: {
        value: [theme.palette.primary.main, theme.palette.secondary.main],
      },
    },
    preset: "confetti",
  }

  const actions = [
    {
      primary: true,
      title: t("page_listing_success.btn_another"),
      onClick: () => {
        history.replace({
          pathname: '/app/swaps/search/page/1',
          state: {
            offeredProduct: product
          }
        })
      }
    },
    {
      title: t("page_listing_success.btn_list"),
      onClick: () => {
        history.replace({
          pathname: './list'
        })
      }
    }
  ]

  useEffect(() => {
    if (location.state?.product) {
      const steps = [
        {
          name: t('steps_list_item.step1'),
          completed: true,
          selected: false
        },
        {
          name: t('steps_list_item.step2'),
          completed: true,
          selected: false
        },
        {
          name: t('steps_list_item.step3'),
          completed: true,
          selected: false
        },
        {
          name: t('steps_list_item.step4'),
          completed: true,
          selected: true
        }
      ]
      setProgression(steps);
      setShowProgression(true);
      setProduct(location.state.product);
    }
  }, [setProduct, location])

  return (
    <>
      <Progress />
      <PageContainer>
        <PageTitle>
          {t("page_listing_success.text_title")}
        </PageTitle>
        <Box sx={{ flex: 1, textAlign: 'center', flexDirection: 'column' }}>
          <Box sx={{ m: 2 }} maxWidth="md" >
            <Typography variant="body1">{t("page_listing_success.text_content")}</Typography>
          </Box>
          {product && (
            <Box sx={{ position: 'relative', p: 2, mb: 6, ml: 'auto', mr: 'auto', maxWidth: 300}} maxWidth="xs">
              <LeftImage>
                <img src={product.images[1]} alt="2" style={{ maxWidth: '200px', height: '100%' }} />
              </LeftImage>
              <RightImage>
                <img src={product.images[2]} alt="3" style={{ maxWidth: '200px', height: '100%' }} />
              </RightImage>
              <CenterImage>
                <img src={product.images[0]} alt="1" style={{ maxWidth: '200px', height: '100%' }} />
              </CenterImage>
            </Box>
          )}
          <ActionBar actions={actions} />
        </Box>
      </PageContainer>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleConfig}
      />
    </>
  )
}
