import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { useApp } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';

// MUI
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

// Custom UI
import ActionBar from '../../components/ActionBar';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import Progress from '../../components/Progress';
import ProductCard from '../products/components/ProductCard';

// Helpers
import useFetch from 'use-http';

// Assets
import SwapImageAsset from '../../images/Swap.svg';

const Item = styled(Container)(({ theme }) => ({
  padding: '1rem'
}));

const swapIconPlacementStyle = {
  display: 'flex',
  position: 'relative',
  top: '-2rem',
  marginBottom: '-2rem',
  justifyContent: 'center'
}

const swapIconStyle = {
  filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5))'
}

export default function SwapRequestPage(props) {

  const { t } = useTranslation();
  const { setProgression, setShowProgression } = useApp();

  const { user } = useAuth();
  const location = useLocation()
  const history = useHistory()

  const [offeredProduct, setOfferedProduct] = useState()
  const [wantedProduct, setWantedProduct] = useState()

  const { post, response } = useFetch(`offers`);

  const steps = [
    {
      name: t('steps_swap_request.step1'),
      completed: true,
      selected: false
    },
    {
      name: t('steps_swap_request.step2'),
      completed: true,
      selected: false
    },
    {
      name: t('steps_swap_request.step3'),
      completed: false,
      selected: true
    },
    {
      name: t('steps_swap_request.step4'),
      completed: false,
      selected: false
    },
  ]

  useEffect(() => {
    setProgression(steps);
    setShowProgression(true);
  }, [])


  useEffect(() => {
    if (location.state?.offeredProduct) {
      setOfferedProduct(location.state.offeredProduct)
    }
    if (location.state?.wantedProduct) {
      setWantedProduct(location.state.wantedProduct)
    }
  }, [setOfferedProduct, setWantedProduct, location])

  console.log('Swap offer', offeredProduct, wantedProduct)
  const actions = [
    {
      primary: true,
      title: 'Request Swap',
      onClick: async () => {
        console.log('Submit new swap offer')

        const offer = await post({
          offered_customer_product_id: offeredProduct.id,
          wanted_customer_product_id: wantedProduct.id,
          message: '',
          // offeredProduct,
          // wantedProduct,
        })
        if (response.ok) {

          console.log('Offer', offer)
          history.push({
            pathname: 'success',
            state: { offeredProduct, wantedProduct, offer }
          })
        } else {
          console.log(response)
        }
      }
    },
    {
      title: 'Cancel',
      onClick: () => {
        history.goBack()
      }
    }
  ]

  return (
    <>
      <Progress />
      { wantedProduct && offeredProduct && (
        <PageContainer>
          <PageTitle>
            {t("swap_offer_make.title")}
          </PageTitle>
          <Stack spacing={2} sx={{ mb: 4 }}>
            <Box sx={{ mt: 0 }}>
              <Paper elevation={8}>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Item>
                    <Box sx={{display: 'flex', justifyContent: 'center' }}>
                      <Chip label={t("swap_offer_make.badge_customer")} color="primary" variant="outlined" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
                    </Box>
                  </Item>
                  <Item>
                    <ProductCard product={user.username === offeredProduct.customer_id ? offeredProduct : wantedProduct} />
                  </Item>
                </Box>
              </Paper>

              <Paper elevation={8}>
                <Box style={swapIconPlacementStyle}><img src={SwapImageAsset} width='48' style={swapIconStyle} alt="" /></Box>
                <Box>
                  <Item>
                    <Box sx={{display: 'flex', justifyContent: 'center' }}>
                      <Chip label={t("swap_offer_make.title_swap_product")}  variant="outlined" color="primary" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
                    </Box>
                  </Item>
                  <Item>
                    <ProductCard product={user.username === offeredProduct.customer_id ? wantedProduct : offeredProduct} />
                  </Item>
                </Box>
              </Paper>
            </Box>
          </Stack>
          <ActionBar actions={actions} />
        </PageContainer>
      )}
    </>
  );
}
