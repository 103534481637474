import React from 'react';
import { useHistory } from "react-router-dom";

// MUI
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 12
  },
}));

export default function NotificationsButton({ count }) {
  const history = useHistory();

  const notifications = () => {
    history.push({
      pathname: '/app/notifications',
    });
  }

  return (
    <StyledBadge badgeContent={count} variant="dot" color="primary_dark">
      <IconButton
        onClick={notifications}
        color="primary_dark"
      >
        <NotificationsNoneIcon />
      </IconButton>
    </StyledBadge>
  );
}
