import React, { useEffect, useState } from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// UI
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";

// Custom UI
import ColorChip from '../../../components/ColorChip';

export default function ProductCarousel({product, footerAsDate}) {

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    setGallery(product.images);
  }, [product.images]);

  if (!product) return null;

  const preventDragHandler = (e) => {
    e.preventDefault();
  }

  const _plugins = [new Pagination({ type: 'bullet' })];

  const colors = {
    color1: product?.colour_1,
    color2:  product?.colour_2,
    multi: false
  }

  let productMeta;

  if (footerAsDate && product.released_at) {
    productMeta = <>{product.released_at}</>
  } else if (product.variant?.name) {
    productMeta = <>Size {product.variant.name}</>
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Flicking
            plugins={_plugins}
            circular
            align="center">
            {gallery.map((image, index) => (
              <Box key={index} sx={{ overflow: "hidden", position: "relative", width: "100%", aspectRatio: "3/4" }}>
                <img
                  onDragStart={preventDragHandler}
                  alt={product.name}
                  src={`${image}?crop=center&height=500`}
                  style={{ objectFit: "cover", height: '100%', width: '100%' }}
                />
              </Box>
            ))}
            <ViewportSlot>
              <div className="flicking-pagination"></div>
            </ViewportSlot>
          </Flicking>
        </Grid>
        <Grid item xs={6}>
          <Container sx={{ p: 2 }} disableGutters>
            <Typography component="h1" variant="h1">{product.name}</Typography>
            <Box display="flex" gap="0.5rem" marginTop="0.5rem">
              <ColorChip colors={ colors } />
              <Typography component="p" variant="p">
                {productMeta}
              </Typography>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}
