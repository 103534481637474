import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

// Custom UI
import PageContainer from '../../components/PageContainer';

// Helpers
import useFetch from 'use-http';

// Context
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../theme/ThemeContext';

const Item = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
}));

const CentreItem = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
}));

export default function Terms() {

  const { t } = useTranslation();
  const theme = useTheme();

  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { post, response } = useFetch('auth');
  const { setIsAcceptedTerms, isAuthenticated } = useAuth();

  const termsChanged = (event) => {
    setTermsAccepted(event.target.checked);
  }

  const handleAcceptTerms = async () => {
    const terms = await post('terms', {
      version: '2022-09-01',
      accepted_at: new Date().toISOString(),
      terms: termsAccepted
    })

    if (response.ok) {
      setIsAcceptedTerms(true);
      history.push({
        pathname: '/'
      });
    }
  }

  if (!isAuthenticated) {
    return(
      <Redirect to="/" />
    )
  }

  return (
    <PageContainer>
      <CentreItem>
        <Box sx={{ pt: 5, pb: 10 }} >
          <img style={{ height: 100, flexGrow: 1 }} src={theme.getLogoSrc()} className="Logo" alt="logo" />
        </Box>
      </CentreItem>
      <Paper sx={{ p: 2 }} elevation={3}>
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {t('page_terms.text_body')}
          </Typography>
          <FormGroup sx={{ textAlign: 'center' }}>
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <FormControlLabel control={<Checkbox onChange={termsChanged} />} label={
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  {t('page_terms.label_checkbox')}
                </Typography>
                }
              />
            </Box>
          </FormGroup>
          <Typography variant="body1">
            {t('page_terms.text_body_readmore')} <Link target="_blank" href="https://thisisunfolded.com/policies/terms-of-service"> {t('page_terms.label_readmore')}</Link>.
          </Typography>
          <Button variant="contained" sx={{ mt: 6 }}onClick={() => handleAcceptTerms()} disabled={!(termsAccepted)} fullWidth={true}>{t('page_terms.btn_accept')}</Button>
        </Box>
      </Paper>
    </PageContainer>
  );
}
