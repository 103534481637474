import React from 'react';
import { Route, Switch }   from "react-router-dom";

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { LoadingProgressBar } from '../context/LoadingContext';

// MUI
import Container from '@mui/material/Container';

// Custom UI
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

// Pages
import WelcomePage from '../pages/home/WelcomePage';
import HowItWorksPage from '../pages/how/HowItWorks';

// Account
import AccountPage from '../pages/account/AccountPage';
import UpdateAccountPage from '../pages/account/UpdateAccountPage';

// Customer
import CustomerProductsPage from '../pages/customer-products/CustomerProductsPage';
import CustomerProductsCreatePage from '../pages/customer-products/CustomerProductsCreatePage';
import CustomerProductsUpdatePage from '../pages/customer-products/CustomerProductsUpdatePage';
import CustomerProductsDetailPage from '../pages/customer-products/CustomerProductsDetailPage';
import CustomerProductsSelectPage from '../pages/customer-products/CustomerProductsSelectPage';
import CustomerProductsSuccessPage from '../pages/customer-products/CustomerProductsSuccessPage';
import CustomerProductsWantingPage from '../pages/customer-products/CustomerProductsWantingPage';
import CustomerProductsWantingDetailPage from '../pages/customer-products/CustomerProductsWantingDetailPage';
import CustomerProductsConfirmPage from '../pages/customer-products/CustomerProductsConfirmPage';
import NotificationsPage from '../pages/notifications/NotificationsPage';
import OfferPage from '../pages/offers/OfferPage';
import OffersPage from '../pages/offers/OffersPage';
import SwapCustomerProductsPage from '../pages/swap/SwapCustomerProductsPage';
import SwapCustomerProductsUpdatePage from '../pages/swap/SwapCustomerProductsUpdatePage';
import SwapViewProductPage from '../pages/swap/SwapViewProductPage';
import SwapRequestPage from '../pages/swap/SwapRequestPage';
import SwapSearchPage from '../pages/swap/SwapSearchPage';
import SwapSuccessPage from '../pages/swap/SwapSuccessPage';
import TokenPage from '../pages/token/TokenPage';
import TokensPage from '../pages/token/TokensPage';
import TokenCreatePage from '../pages/token/TokenCreatePage';

export default function Main({ match }) {

  const { t } = useTranslation();

  return (
    <>
      <Navigation />
      <LoadingProgressBar />
      <Container maxWidth="xl" disableGutters={true}>

        <Switch>
          <Route path={`${match.url}/welcome`} render={(props) => (<WelcomePage {...props} />)} />
          <Route path={`${match.url}/how-it-works`} render={(props) => (<HowItWorksPage {...props} />)} />

          <Route path={`${match.url}/account/update`} render={(props) => (<UpdateAccountPage {...props} />)} />
          <Route path={`${match.url}/account`} render={(props) => (<AccountPage {...props} />)} />

          <Route path={`${match.url}/customer-products/create/confirm`} render={(props) => (<CustomerProductsConfirmPage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/details`} render={(props) => (<CustomerProductsCreatePage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/product`} render={(props) => (<CustomerProductsDetailPage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/success`} render={(props) => (<CustomerProductsSuccessPage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/select/page/:page`} render={(props) => (<CustomerProductsSelectPage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/wanting`} render={(props) => (<CustomerProductsWantingPage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/wanting-detail`} render={(props) => (<CustomerProductsWantingDetailPage {...props} />)} />
          <Route path={`${match.url}/customer-products/create/list`} render={(props) => (<CustomerProductsPage type="customer" {...props} />)} />

          <Route path={`${match.url}/customer-products/update/:customerProductId/wanting-detail`} render={(props) => (<CustomerProductsWantingDetailPage action='update' {...props} />)} />
          <Route path={`${match.url}/customer-products/update/:customerProductId/wanting`} render={(props) => (<CustomerProductsWantingPage {...props} />)} />
          <Route path={`${match.url}/customer-products/update/:customerProductId`} render={(props) => (<CustomerProductsUpdatePage action="update" {...props} />)} />
          <Route path={`${match.url}/customer-products/wanted/list`} render={(props) => (<CustomerProductsPage type="wanted" {...props} />)} />

          <Route path={`${match.url}/swaps/view/:customerProductId`} render={(props) => (<SwapViewProductPage {...props} />)} />
          <Route path={`${match.url}/swaps/create`} render={(props) => (<CustomerProductsCreatePage {...props} />)} />

          <Route path={`${match.url}/swaps/offer`} render={(props) => (<SwapCustomerProductsPage {...props} />)} />

          <Route path={`${match.url}/swaps/update`} render={(props) => (<SwapCustomerProductsUpdatePage {...props} />)} />
          <Route path={`${match.url}/swaps/request`} render={(props) => (<SwapRequestPage {...props} />)} />
          <Route path={`${match.url}/swaps/success`} render={(props) => (<SwapSuccessPage {...props} />)} />
          <Route path={`${match.url}/swaps/search/page/:page`} render={(props) => (<SwapSearchPage title={t('search.text_title_find')} {...props} />)} />

          <Route path={`${match.url}/tokens/create`} render={(props) => (<TokenCreatePage {...props} />)} />
          <Route path={`${match.url}/tokens/:tokenId`} render={(props) => (<TokenPage {...props} />)} />
          <Route path={`${match.url}/tokens`} render={(props) => (<TokensPage {...props} />)} />

          <Route path={`${match.url}/wanted`} render={(props) => (<SwapSearchPage filter="wanted" title={t('search.text_title_wanted')} {...props} />)} />
          <Route path={`${match.url}/notifications`} render={(props) => (<NotificationsPage {...props} />)} />

          <Route path={`${match.url}/offers/:offerId`} render={(props) => (<OfferPage {...props} />)} />
          <Route path={`${match.url}/offers`} render={(props) => (<OffersPage {...props} />)} />

          <Route path={`${match.url}/`} render={(props) => (<WelcomePage {...props} />)} />

        </Switch>
      </Container>
      <Footer />
    </>
  );
}
