import * as React from 'react';
import { useHistory } from "react-router-dom";

// MUI
import IconButton from '@mui/material/IconButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export default function AccountButton() {
  const history = useHistory();

  const account = () => {
    history.push({
      pathname: '/app/account',
    });
  }

  return (
    <div>
      <IconButton
        onClick={account}
        color="primary_dark"
      >
        <AccountCircleOutlinedIcon />
      </IconButton>
    </div>
  );
}
