import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

// Custom UI
import AddressForm from '../../components/AddressForm';
import PhoneNumberForm from '../../components/PhoneNumberForm';

// Helpers
import useFetch from 'use-http';
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Context
import { useAuth } from '../../context/AuthContext';

const Item = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
}));


export default function MyAccount() {

  const { user } = useAuth()
  const location = useLocation()
  const history = useHistory()

  const defaultValues = {
    phone: '',
    ship_from: {
      name: '',
      company: '',
      address1: '',
      address2: '',
      city: '',
      province: '',
      zip: '',
    },
    ship_to: {
      name: '',
      company: '',
      address1: '',
      address2: '',
      city: '',
      province: '',
      zip: '',
    }
  };

  const { handleSubmit, control,  formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const { post, put, response, loading } = useFetch('/tokens');

  const [token, setToken] = useState(defaultValues);
  const [offer, setOffer] = useState()

  const onSubmit = async (data) => {
    console.log('Token data', data)

    if (offer) {
      data.offer_id = offer.id
    }

    const token = await post(data)

    if (response.ok) {
      console.log('Token', token)
      history.replace({
        pathname: `/app/tokens/${token.id}`,
        state: { token }
      })
    }
  }

  useEffect(() => {
    if (location.state?.offer) {
      setOffer(location.state?.offer)
    }

  }, [setOffer, location])
  return (
    <Container sx={{ p: 2 }}>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Typography variant="h1">Create Token</Typography>
            <Item>
              <PhoneNumberForm control={control} phone={token.phone} errors={errors} />
            </Item>
            <Item>
              <AddressForm title="Your address" keyName="ship_from" address={token.ship_from} control={control} errors={errors} />
            </Item>
            <Item>
              <AddressForm title="Their address" keyName="ship_to" address={token.ship_to} control={control} errors={errors} />
            </Item>
            <Item>
              <LoadingButton fullWidth disabled={!isValid} loading={loading} type="submit" variant="contained">Claim postage</LoadingButton>
            </Item>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
}
