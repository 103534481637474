
import React from 'react';

// MUI
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export default function Main({ centerOnScreen, size = 40, sx }) {

  const spinnerPlacementHeight = centerOnScreen ? '100vh' : 'auto';

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: spinnerPlacementHeight }}
    >
      <Grid item sx={{ mt: 4, mb: 2 }} xs={3}>
        <CircularProgress size={size} sx={sx} />
      </Grid>
    </Grid>
  );
}
