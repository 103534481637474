import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";

// Language, translations and display strings
import './i18n/i82n';

// Custom UI
import App from './App';
import Spinner from './components/Spinner';

// Helpers
import reportWebVitals from './reportWebVitals';

// Assets
import './index.scss';

// Contexts
import { AuthProvider } from './context/AuthContext';
import { AppProvider } from './context/AppContext';
import { LoadingProvider } from './context/LoadingContext';

import * as Sentry from "@sentry/react";

if (import.meta.env.PROD && import.meta.env.VITE_SWAP_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SWAP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/swap/],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthProvider>
      <AppProvider>
        <LoadingProvider>
          <Suspense fallback={<Spinner centerOnScreen />} >
            <App />
          </Suspense>
        </LoadingProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>,
);

reportWebVitals();
