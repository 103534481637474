import React, { useEffect, useState, createContext, useContext } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { useHead } from 'hoofd';

// Themes
import { customTheme as default_theme, settings as default_settings } from './themes/theme_default';
import { customTheme as unfolded_theme, settings as unfolded_settings } from './themes/theme_unfolded';
import { customTheme as tallternative_theme, settings as tallternative_settings } from './themes/theme_tallternative';
import { customTheme as fabulous_theme, settings as fabulous_settings } from './themes/theme_fabulous';

// The context held in this context file are general UI and shared across
// many components.
const hostname = window.location.hostname;

const domainThemes = {
  'localhost': 'unfolded',
  'dev': 'unfolded',
  'thisisunfolded': 'unfolded',
  'tallternative': 'tallternative',
  'fabulousclothing': 'fabulous',
};

const settingsMap = {
  default: default_settings,
  unfolded: unfolded_settings,
  tallternative: tallternative_settings,
  fabulous: fabulous_settings,
};

const themeMap = {
  default: default_theme,
  unfolded: unfolded_theme,
  tallternative: tallternative_theme,
  fabulous: fabulous_theme,
};

const ThemeContext = createContext(null);

const activeTheme = domainThemes[Object.keys(domainThemes).find(domain => hostname.includes(domain))] || 'unfolded';

export const ThemeProvider = ({ children }) => {

  const [theme, setTheme] = useState(activeTheme);
  const [muiTheme, setMuiTheme] = useState(themeMap[theme]);
  const [settings, setSettings] = useState(settingsMap[theme]);

  useEffect(() => {
    setMuiTheme(themeMap[theme])
    setSettings(settingsMap[theme])
  }, [theme])

  useHead({
    title: settings.title
  });

  const getLogoSrc = () => {
    return `/themes/${settings.name}/${settings.logo}`
  }

  const getBrandName = () => {
    return settings.displayName
  }

  return (
    <ThemeContext.Provider value={{
      getBrandName,
      getLogoSrc,
      setSettings,
      setTheme,
      settings,
      theme
    }}>
      <MUIThemeProvider theme={muiTheme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);