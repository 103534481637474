import React from 'react';

// Contexts
import { useApp } from '../context/AppContext';

// MUI
import CheckIcon from '@mui/icons-material/Check';

// Assets
import "./Progress.scss";

export default function Progress() {

  const { progression, showProgression } = useApp();

  // Example of how to use the progression
  // const steps = [
  //   {
  //     name: "List",
  //     completed: true,
  //     selected: false
  //   },
  //   {
  //     name: "Find",
  //     completed: false,
  //     selected: true
  //   },
  //   {
  //     name: "Confirm",
  //     completed: false,
  //     selected: false
  //   },
  //   {
  //     name: "Post",
  //     completed: false,
  //     selected: false
  //   },
  // ]
  // const { setProgression, setShowProgression } = useApp();
  // setProgression(steps);

  if (showProgression && progression.length) {
    return (
      <div className="progress">
        <div className="progress__container">
          { progression.map((step, index) => {
            return (
              <div key={index} className={`progress__item ${step.selected ? 'selected' : ''} ${step.completed ? 'completed' : ''}`}>
                <span className="progress__spot">{step.completed && <CheckIcon/> }</span>
                <span className="progress__label">{ step.name }</span>
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  return null;
}