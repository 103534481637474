import React from 'react';

// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

export default function ActionBar({ direction = 'column', actions, buttons }) {

  return (
    <>
      <Divider />
      <Container maxWidth="sm" disableGutters sx={{ mt: 2, mb: 4}}>
        <AppBar position="relative" color="secondary" elevation={0}>
          <Stack direction={direction} spacing={2} sx={{ p: 1, pl: 2, pr: 2 }}>
            { buttons }
            {actions && actions.map((action, i) => (
              <LoadingButton
                loading={action.loading}
                fullWidth
                key={i}
                disabled={action.disabled}

                variant={action.primary ? 'contained' : action.link ? 'text' : 'outlined' }
                {...(action.submit && { type: 'submit' })}
                {...(!action.submit && { onClick: action.onClick })}>
                {action.title}
              </LoadingButton>
              ))}
          </Stack>
        </AppBar>
      </Container>
    </>
  )
}