import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Custom UI
import ResetPasswordFields from './components/ResetPasswordFields';

// Hooks
import { useTheme } from '../../theme/ThemeContext';


const Center = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
}));

export default function ResetPasswordPage() {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Container sx={{ flex: 1 }}>
        <Center>
          <Box sx={{ pt: 5, pb: 5 }} >
            <img style={{ height: 100, flexGrow: 1 }} src={theme.getLogoSrc()} className="Logo" alt="logo" loading="lazy" />
          </Box>
        </Center>
        <ResetPasswordFields />
      </Container>
    </Stack>
  );
}
