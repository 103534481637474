import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Helpers
import { formatDistanceToNowStrict } from 'date-fns'
import useFetch from 'use-http';

export default function OfferSwapDetails({ offer, isOfferer }) {

  const { t } = useTranslation()

  const history = useHistory()
  const [validTokens, setValidTokens] = useState()
  const [hasValidToken, setHasValidToken] = useState(false)
  const { get, response } = useFetch('/tokens')

  const checkTokens = useCallback(async () => {
    const validTokens = await get('/check')

    if (response.ok) {
      console.log('Valid tokens', validTokens)
    }

    setValidTokens(validTokens)

    if (validTokens.tokens && validTokens.tokens > 0) {
      setHasValidToken(true)
    }
  }, [get, response])

  useEffect(() => {
    checkTokens()
  }, [checkTokens])

  const handleCreateToken = () => {
    history.push({
      pathname: '/app/tokens/create',
      state: { offer }
    })
  }

  const contactName = isOfferer ? offer.wanted_details?.name : offer.offered_details?.name
  const contactEmail = isOfferer ? offer.wanted_details?.email : offer.offered_details?.email

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Stack spacing={1} sx={{ flex: 1 }}>
          <Typography variant="body1">{t('swap_details.text_details', { name: contactName })}</Typography>
          <Typography variant="body1"><a href={`mailto:${contactEmail}`}>{contactEmail}</a></Typography>
          <Typography variant="body2">{formatDistanceToNowStrict(offer.accepted_at)}</Typography>
          { hasValidToken && <Button variant="outlined" onClick={handleCreateToken}>{t('swap_details.btn_create')}</Button>}
        </Stack>
      </Stack>
      <Divider sx={{ pt: 2 }} />
    </Box>
  );
}
