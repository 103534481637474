import React from 'react';
import { useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default function TokenTrackingDetails({ token }) {

  const { t } = useTranslation()

  const history = useHistory()

  const handleOpenUrl = () => {
    window.open(token.tracking.url, '_blank')
  }

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Stack spacing={1} sx={{ flex: 1 }}>
          <Typography variant="body1">{t('token_tracking.text_title')}</Typography>
          <Typography variant="body1">{token.tracking.company}</Typography>
          <Typography variant="body2">{token.tracking.number}</Typography>
          { token.tracking.url && <Button variant="outlined" onClick={handleOpenUrl}>{t('token_tracking.btn_view')}</Button>}
        </Stack>
      </Stack>
      <Divider sx={{ pt: 2 }} />
    </Box>
  );
}
