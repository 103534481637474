import React from 'react';
import { useHistory } from 'react-router-dom';

// MUI

// Custom UI
import ProductsSearch from '../products/components/ProductsSearch';
import ActionBar from '../../components/ActionBar';

export default function CustomerProductsSelectPage({ match, type }) {

  const history = useHistory();

  const handleSelectProduct = (product) => {
    history.push({
      pathname: '../../details',
      state: { product }
    })
  }

  const actions = [
    {
      title: 'Cancel',
      onClick: () => {
        history.goBack();
      }
    }
  ]

  return (
    <>
      <ProductsSearch type="products" showRequestSwapButton={false} title={"Find the item you have to swap"} onProductClick={handleSelectProduct} />
      <ActionBar actions={actions} />
    </>
  );
}
