import React, { useState, createContext, useContext } from 'react';
import * as Sentry from '@sentry/react'

// Helpers
import useFetch from 'use-http';

// Create the context
const AuthContext = createContext();

const hostname = window.location.hostname;
const API = import.meta.env.VITE_SWAP_API || `https://swap-api.${hostname.split('.').slice(1).join('.')}`

console.log('API', API);

export const AuthProvider = ({ children }) => {

  const { post, response } = useFetch(`${API}/auth`, {
    cachePolicy: 'no-cache'
  });

  // Using the useState hook to keep track of the value authed (if a
  // user is logged in)
  const loggedIn = localStorage.getItem('id_token');
  const localUser = JSON.parse(localStorage.getItem('user')) || null;

  const [isAuthenticated, setIsAuthenticated] = useState(!!loggedIn);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(!!(localUser?.terms && localUser?.terms['2022-09-01']?.terms));
  const [user, setUser] = useState(localUser);
  const [token, setToken] = useState(loggedIn);

  const { get: userGet, response: userResponse } = useFetch(`${API}/auth/user`, {
    cachePolicy: 'no-cache',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const login = async (params) => {
    const authResponse = await post('login', params)

    if (response.ok) {
      localStorage.setItem('user', JSON.stringify(authResponse.customer));
      localStorage.setItem('id_token', authResponse.id_token);
      localStorage.setItem('access_token', authResponse.access_token);
      localStorage.setItem('refresh_token', authResponse.refresh_token);
      setUser(authResponse.customer);
      setToken(authResponse.id_token);
      setIsAcceptedTerms(!!(authResponse.customer.terms && authResponse.customer?.terms['2022-09-01']?.accepted_at));
      setIsAuthenticated(true);
    }

    return response.ok;
  };

  const logout = async () => {
    console.log("The User has logged out");
    localStorage.clear();
    Sentry.setUser(null)
    setUser(null);
    setToken(null);
    setIsAuthenticated(false);
    setIsAcceptedTerms(false);
    window.scrollTo(0, 0);
  };

  const refreshToken = async () => {
    const refreshResponse = await post('refresh', {
      refresh_token: localStorage.getItem('refresh_token')
    })

    console.log('Refresh token', refreshResponse);

    if (refreshResponse.ok) {
      localStorage.setItem('id_token', refreshResponse.id_token);
      localStorage.setItem('access_token', refreshResponse.access_token);
      setToken(refreshResponse.id_token);
    } else {
      logout();
    }
  }

  const forgottenPassword = async (params) => {
    const authResponse = await post('forgotten-password', params);

    console.log('CONTEXT Reset password', params);

    if (response.ok) {
      console.log('Requesting a password reset token...', authResponse);
    }

    return response.ok;
  };

  const resetPassword = async (params) => {
    const authResponse = await post('reset-password', params);

    if (response.ok) {
      console.log('Applying new password', authResponse);
    }

    return response.ok;
  };

  const getUser = async () => {
    if (token) {
      const user = await userGet();

      if (userResponse.ok) {
        console.log('[AuthContext]: getUser', user);
        updateUser(user);
        Sentry.setUser({
          id: user.id,
          email: user.email,
          username: user.username,
          first_name: user.first_name,
          last_name: user.last_name,
        });
        return user;
      }
    } else {
      console.log('[AuthContext]: No known token, cannot get user');
      return null;
    }
  }

  const updateUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    setIsAcceptedTerms(!!(user?.terms && user?.terms['2022-09-01']?.terms))
    setUser(user);
  }

  const acceptTerms = async (params) => {
    const terms = await post('terms', params);

    if (response.ok) {
      setIsAcceptedTerms(terms);
    }
  }

  return (
    // Using the provider so that ANY component in our application can
    // use the values that we are sending.
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      isAcceptedTerms,
      setIsAcceptedTerms,
      resetPassword,
      forgottenPassword,
      login,
      logout,
      refreshToken,
      acceptTerms,
      getUser,
      user,
      updateUser,
      token
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext);