import React from 'react';

// MUI
import { styled } from '@mui/material/styles';
import { Box, FormControl, InputLabel, Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';

// Context
import { useTheme } from './ThemeContext';

const hostname = window.location.hostname;

const themeList = [
  {
    id: 'unfolded',
    name: 'Unfolded'
  },
  {
    id: 'tallternative',
    name: 'TALLternative'
  },
  {
    id: 'fabulous',
    name: 'Fabulous'
  }
]

const ThemePickerBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "4px",
  left: "4px",
  margin: "1rem",
  padding: "12px 6px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

const SelectionItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
}));

export default function ThemePicker() {

  const {
    theme, setTheme
  } = useTheme();

  const handleThemeChange = (theme) => {
    setTheme(theme)
  }

  if (hostname.includes('localhost') || hostname.includes('dev.') || hostname.includes('staging.')) {
    return (
      <ThemePickerBox>
        <FormControl>
          <InputLabel>Themes</InputLabel>
          <Select
            value={theme}
            label="Themes"
            onChange={(e) => handleThemeChange(e.target.value)}
          >
            { themeList.map((t, index) => (
              <MenuItem key={index} value={t.id}>
                <SelectionItemBox>
                  <ListItemIcon>
                    <PaletteIcon />
                  </ListItemIcon>
                  <ListItemText primary={t.name} />
                </SelectionItemBox>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemePickerBox>
    )
  }
  return null;
}