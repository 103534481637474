import React from 'react';

// MUI
import Box from '@mui/material/Box';

// Context
import { useAuth } from '../context/AuthContext';

// Assets
import SwapIcon from '../images/Swap.svg';

export default function MySwap({ product }) {

  const { isAuthenticated } = useAuth();

  if (product.is_offered && isAuthenticated) {
    return (
      <Box style={{position: 'relative'}}>
        <img src={SwapIcon} alt="" style={{ top: 10, left: 10, width: 32, height: 32, borderRadius: '50%', position: 'absolute', zIndex: 100}} />
      </Box>
    );
  }

  return (
    <></>
  );
}