import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { useApp } from '../../context/AppContext';

// MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'

// Custom UI
import ProductRow from '../products/components/ProductRow';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import Spinner from '../../components/Spinner';
import Progress from '../../components/Progress';
import Alert from '../../components/Alert';
import Popup from '../../dialog/Popup';

// Helpers
import useFetch from 'use-http';

// Dialogs
import { useDialog } from "../../dialog/DialogProvider";

const buttonStyle = {
  minWidth: 130,
};

export default function CustomerProductsPage({ type }) {

  const { setProgression, setShowProgression } = useApp();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const { t } = useTranslation();

  const [offeredProduct, setOfferedProduct] = useState();
  const [wantedProduct, setWantedProduct] = useState();

  const [products, setProducts] = useState([]);

  const steps = [
    {
      name: t('steps_swap_request.step1'),
      completed: false,
      selected: true
    },
    {
      name: t('steps_swap_request.step2'),
      completed: false,
      selected: false
    },
    {
      name: t('steps_swap_request.step3'),
      completed: false,
      selected: false
    },
    {
      name: t('steps_swap_request.step4'),
      completed: false,
      selected: false
    },
  ];

  const [openDialog, closeDialog] = useDialog();

  useEffect(() => {
    setProgression(steps);
    setShowProgression(true);
  }, [])

  useEffect(() => {
    if (location.state?.offeredProduct) {
      setOfferedProduct(location.state.offeredProduct);
    }
    if (location.state?.wantedProduct) {
      setWantedProduct(location.state.wantedProduct);
    }
  }, [setOfferedProduct, setWantedProduct, location]);

  const { del, post, response, loading, error } = useFetch(`customer-products`);

  useEffect(() => {
    async function initializeSwaps() {
      const products = await post('search', {
        filters: type === 'wanted' ?
          [{ name: 'status.keyword', values: ['wanted'] }]
          :
          [{ name: 'status.keyword', values: ['owned', 'listed'] }]
       });

      if (response.ok) {
        setProducts(products.records);
      }
    }

    initializeSwaps();
  }, [post, response, type]);

  const handleSwapProduct = (product) => {
    window.scrollTo(0, 0);
    if (wantedProduct) {
      history.push({
        pathname: 'details',
        state: { product, wantedProduct, offeredProduct }
      })
    } else {
      history.push({
        pathname: '/app/swaps/search/page/1',
        state: { product, wantedProduct, offeredProduct: product }
      })
    }
  }

  const showDeleteProductDialog = (product) => {
    openDialog({
      children: (
        <Popup details={{
          buttonStyle: "contained",
          popupTitle: t('dialog_delete_item.title'),
          bodyText: t('dialog_delete_item.text'),
          proceedTitle: t('dialog_delete_item.btn_action'),
          cancelTitle: t('dialog_delete_item.btn_cancel'),
          showCancel: true,
          proceedHandler: () => {
            console.log("Deleting product", params.customerProductId);
            handleDeleteProduct(product);
            closeDialog();
          },
          closeHandler: () => {
            closeDialog();
          }
        }} />
      )
    });
  }

  const handleEditProduct = (product) => {
    history.push({
      pathname: `../update/${product.id}`,
      state: { product }
    })
  }

  const handleDeleteProduct = async (product) => {
    console.log('Deleting product', product)
    await del(product.id)

    if (response.ok) {
      console.log('Successfully deleted product', product.id)
      const removedProducts = products.filter(p => p.id !== product.id)
      setProducts(removedProducts)
    }
  }

  const handleListProduct = (product) => {
    history.push({
      pathname: `../update/${product.id}`,
      state: { product, wantedProduct, offeredProduct }
    })
  }

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  }

  const productsListed = products.filter( product => product.is_listed);
  const productsUnlisted = products.filter( product => !product.is_listed);

  return (
    <>
      <Progress />
      <PageContainer>

        <PageTitle>
          {type === 'wanted' ? t('page_customer_products.text_title_wanted_items') : t('page_customer_products.text_title_customer_items')}
        </PageTitle>

        <Typography component="h2" variant='h2'>
          {type === 'wanted' ? t('page_customer_products.text_title_wanted_items') : t('page_customer_products.text_title_unlisted')}
        </Typography>

        <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
          { loading && !error &&
            <Spinner />
          }
          { !loading && !error &&
            <Box>
              {
                productsUnlisted.length ? productsUnlisted.map((product) => (
                  <ProductRow key={product.id} product={product} actions={(
                    <Stack direction="row" spacing={{ xs: 2 }}>
                      <Button variant="contained" size="small" style={buttonStyle} onClick={() => handleListProduct(product)}>{t('page_customer_products.btn_list')}</Button>
                      <IconButton onClick={() => showDeleteProductDialog(product)}><DeleteIcon /></IconButton>
                    </Stack>
                  )} />
                )) : <Alert severity="info">{t('page_customer_products.text_error_no_unlisted_items')}</Alert>
              }
            </Box>
          }
          { !loading && error &&
            <Alert severity="error">{t('global.text_error')}</Alert>
          }
        </Paper>

        <Typography component="h2" variant='h2'>
          {type === 'owned' ? t('page_customer_products.text_title_wanted_items') : t('page_customer_products.text_title_listed')}
        </Typography>

        <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
          { loading && !error &&
            <Spinner />
          }
          { !loading && !error &&
            <Box>
              {
                productsListed.length ? productsListed.map((product) => (
                  <ProductRow key={product.id} product={product} actions={(
                    <Stack direction="row" spacing={{ xs:2 }}>
                      <Button variant="contained" size="small" style={buttonStyle} onClick={() => handleSwapProduct(product)}>{t('page_customer_products.btn_swap')}</Button>
                      <IconButton onClick={() => handleEditProduct(product)}><EditIcon /></IconButton>
                    </Stack>
                    )} />
                )) : <Alert severity="info">{t('page_customer_products.text_error_no_listed_items')}</Alert>
              }
            </Box>
          }
          { error &&
            <Alert severity="error">{t('global.text_error')}</Alert>
          }
        </Paper>

      </PageContainer>

      <PageContainer>
        <Box sx={{ m: 2}}>
          <Typography sx={{ mb: 2 }}  align='center'>
            {t('page_customer_products.text_item_missing')} <Link onClick={handleScrollTop} to="/app/customer-products/create/select/page/1">{t('page_customer_products.link_add_item')}</Link>
          </Typography>
        </Box>
      </PageContainer>
    </>
  )
}
