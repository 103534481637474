import React from "react";

// MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Helpers
import { Controller } from "react-hook-form";

const MultiSelect = ({
  placeholder,
  control,
  options,
  name,
  label,
  required = false,
  disabled = false,
  defaultValue = [],
}) => {
  return (
    <FormControl>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        id={name}
        name={name}
        control={control}
        rules={{
          required,
        }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            displayEmpty={true}
            multiple
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            labelId={`${name}-label`}
            label={label}
            renderValue={(selected) => {
              return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const option = JSON.parse(value || "[]")
                  return (
                    <Chip key={option.id} label={option.title} />
                  )
                }
                )}
              </Box>
            )}}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={JSON.stringify(option || [])}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default MultiSelect;
