import React from "react";
import { Controller } from "react-hook-form";

// MUI
import TextField from "@mui/material/TextField";

const TIUTextField = ({
  placeholder,
  type = "text",
  control,
  name,
  label,
  required = false,
  defaultValue = "",
  helperText,
  errors,
  multiline,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          required={required}
          label={label}
          type={type}
          multiline={multiline}
          placeholder={placeholder}
          error={!!errors.password}
          helperText={!!errors.password ? helperText : ""}
        />
      )}
    />
  );
};

export default TIUTextField;
