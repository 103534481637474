import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// Custom UI
import OfferListItem from './components/OfferListItem';
import PageContainer from '../../components/PageContainer';
import Alert from '../../components/Alert';

// Custom UI
import PageTitle from '../../components/PageTitle';
import Spinner from '../../components/Spinner';

// Helpers
import useFetch from 'use-http';

// Context
import { useAuth } from '../../context/AuthContext';

export default function ItemsPage() {

  const { t } = useTranslation();

  const history = useHistory();

  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  const { post, response } = useFetch(`offers`);

  useEffect(() => {
    async function initializeSwaps() {
      const offers = await post('search', {});

      if (response.ok) {
        console.log('Setting offers', offers);
        setOffers(offers.records);
        setIsLoading(false);
      }
    }

    initializeSwaps()
  }, [post, response]);

  const handleOfferClick = (offer) => {
    history.push({
      pathname: `./offers/${offer.id}`,
      state: { offer }
    })
  }

  const offersRecieved = offers.filter(o => o.wanted_customer_id === user.username && o.status === 'offered') || [];
  const offersOffered = offers.filter(o => o.customer_id === user.username && o.status === 'offered') || [];
  const offersAccepted = offers.filter(o => o.status === 'accepted') || [];
  const offersDeclined = offers.filter(o => o.status === 'declined') || [];
  const offersCancelled = offers.filter(o => o.status === 'cancelled') || [];

  return (
    <PageContainer>

      <PageTitle>
        {t('page_offers.text_title')}
      </PageTitle>

      { offersAccepted.length > 0 && (
        <>
          <Typography component="h2" variant='h2'>
            {t('page_offers.text_title_accepted')}
          </Typography>

          <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
            { isLoading &&
              <Spinner />
            }
            {offersAccepted.length > 0 && offersAccepted.map((offer, i) => (
              <OfferListItem
                offer={offer}
                key={offer.id}
                onClick={() => handleOfferClick(offer)}
              />
            ))}
            { !offersAccepted.length && !isLoading &&
              <Box sx={{paddingBlock:2}}>
                <Alert message={t('page_offers.text_warning_accepted_none')} severity="info" showPatch />
              </Box>
            }
          </Paper>
        </>
      )}

      <Typography component="h2" variant='h2'>
        {t('page_offers.text_title_received')}
      </Typography>

      <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
        { isLoading &&
          <Spinner />
        }
        {offersRecieved.length > 0 && offersRecieved.map((offer, i) => (
          <OfferListItem
            offer={offer}
            key={offer.id}
            onClick={() => handleOfferClick(offer)}
          />
        ))}
        { !offersRecieved.length && !isLoading &&
          <Box sx={{paddingBlock:2}}>
            <Alert message={t('page_offers.text_warning_received_none')} severity="info" showPatch />
          </Box>
        }
      </Paper>

      <Typography component="h2" variant='h2'>
        {t('page_offers.text_title_offered')}
      </Typography>

      <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
        { isLoading &&
          <Spinner />
        }
        {offersOffered.length > 0 && offersOffered.map((offer, i) => (
          <OfferListItem
            offer={offer}
            key={offer.id}
            sx={{ bottomBorder: '1px solid #ccc' }}
            onClick={() => handleOfferClick(offer)}
          />
        ))}
        { !offersOffered.length && !isLoading &&
          <Box sx={{p:2}}>
            <Alert message={t('page_offers.text_warning_offered_none')} severity="info" showPatch />
          </Box>
        }
      </Paper>

      <Typography component="h2" variant='h2'>
        {t('page_offers.text_title_declined')}
      </Typography>

      <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
        { isLoading &&
          <Spinner />
        }
        {offersDeclined.length > 0 && offersDeclined.map((offer, i) => (
          <OfferListItem
            offer={offer}
            key={offer.id}
            onClick={() => handleOfferClick(offer)}
          />
        ))}
        { !offersDeclined.length && !isLoading &&
          <Box sx={{paddingBlock:2}}>
            <Alert message={t('page_offers.text_warning_declined_none')} severity="info" showPatch />
          </Box>
        }
      </Paper>
    </PageContainer>
  );
}
