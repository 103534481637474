import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Contexts
import { useAuth } from '../context/AuthContext';

function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated, isAcceptedTerms } = useAuth();
  return (
    <Route {...rest} render={(props) =>
      (isAuthenticated ?
        isAcceptedTerms ?
        (<Component {...props} />) : (<Redirect to="/auth/terms" />) :(<Redirect to="/" />))
    }/>
  );
}

export default PrivateRoute;
