
import React from 'react';

// MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Contexts
import { useTheme } from '../theme/ThemeContext';

const BannerBox = styled(Box)(({ theme }) => ({
  "position": "absolute",
  "width": "100%",
  "height": "300px",
  "top": 0,
  "left": 0,
  "overflow": "hidden",
  "zIndex": "-1",
  "transition": "300ms"
}));

const styles = {
  image: {
    "display": "block",
    "position": "relative",
    "width": "100%",
    "height": "100%",
    "objectFit": "cover",
    "objectPosition": "50% 0"
  }
};

export default function Main() {

  const theme = useTheme();

  return (
    <BannerBox>
      <img style={styles.image} src={`/themes/${theme.settings.name}/${theme.settings.banner}`} alt="banner" />
    </BannerBox>
  );
}
