import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

// Custom UI
import ProductsGrid from '../products/components/ProductsGrid';
import PageMasthead from '../../components/PageMasthead';
import PageContainerWide from '../../components/PageContainerWide';
import Alert from '../../components/Alert';

// Helpers
import useFetch from 'use-http';

// Contexts
import { useTheme } from '../../theme/ThemeContext';

export default function WelcomePage() {

  const theme = useTheme();

  const { t } = useTranslation();
  const history = useHistory();

  const [products, setProducts] = useState([]);

  const pageSize = 12;

  const { post, response, error, loading } = useFetch('swap-products/search');

  useEffect(() => {
    async function getProducts() {
      const products = await post({ limit: pageSize });
      if (response.ok) {
        setProducts(products.records);
      }
    };
    getProducts();
  }, [post, response]);

  const handleProductClick = (selectedProduct) => {
    console.log('Selected product', selectedProduct)
    if (selectedProduct?.is_offered) {
      history.push({
        pathname: `/app/offers/${selectedProduct.offer_id}`
      })
    } else {
      history.push({
        pathname: `/app/swaps/view/${selectedProduct.id}`,
        state: { wantedProduct: selectedProduct }
      })
    }
  }

  const handleViewListings = (product) => {
    history.push({
      pathname: `/app/swaps/search/page/1`
    })
    window.scrollTo(0, 0);
  }

  return (
    <>
      <PageMasthead>
        <Typography component="h1" variant="h1" align='center'>{t('page_welcome.text_title', {title: theme.settings.title})}</Typography>
        <Typography component="p" variant="body1" align='center'>{t('page_welcome.text_subtitle')}</Typography>
        <Button component={RouterLink} to="/app/customer-products/create/list" variant="contained" fullWidth={true}>
          {t('page_welcome.btn_swap')}
        </Button>
        <Button component={RouterLink} to="/app/swaps/search/page/1" variant="outlined" fullWidth={true}>
          {t('page_welcome.btn_browse')}
        </Button>
        <Link align="center" href="/app/how-it-works" underline="always">{t('page_welcome.link_how_it_works')}</Link>
      </PageMasthead>

      { !error &&
        <PageContainerWide>
          <ProductsGrid
            products={products}
            onItemClick={handleProductClick}
            heading={t('page_welcome.label_product_grid')}
            linktitle={t('page_welcome.link_show_all')}
            link="/app/swaps/search/page/1"
            showRequestSwapButton
            isLoading={loading} />
          <Box component="span" sx={{ mt: 2 }}>
            <Divider />
            <Container disableGutters maxWidth="sm" sx={{ mt: 2, mb: 2, pr: 2, pl: 2 }}>
              <Button fullWidth variant="outlined" onClick={handleViewListings}>
                {t('page_welcome.btn_search_all')}
              </Button>
            </Container>
          </Box>
        </PageContainerWide>
      }

      { !loading && error &&
        <Alert severity="error">{t('global.text_error')}</Alert>
      }
    </>
  );
}
