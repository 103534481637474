import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";

// i18n
import { useTranslation } from 'react-i18next';

// MUI;
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditOutlined'

// Contexts
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../theme/ThemeContext';

export default function Account() {

  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();
  const history = useHistory();

  const [user, setUser] = useState(auth.user);

  useEffect(() => {
    setUser(auth.user);
  }, [auth.user]);

  const handleLogout = () => {
    auth.logout();
  }

  const handleEdit = () => {
    history.push('/app/account/update');
  }

  return (
    <Container sx={{ p: 2 }} maxWidth="md" >
      <Stack spacing={2}>
        <Typography component="h1" variant="h1" align='center' sx={{ pt: 2 }}>{t('page_account.text_title')}</Typography>
        <Typography component="h2" variant="h2">{t('page_account_profile.text_title')}</Typography>

        <Paper sx={{ pt: 4, pb: 2, pl: 2, pr: 2, position: "relative"}}>
          <Box sx={{ position: "absolute", top: "1rem", right: "1rem"}}>
            <IconButton onClick={() => handleEdit()}><EditIcon /></IconButton>
          </Box>
          <Stack spacing={2}>
            <Typography component="p" variant="smalllabel">
              {t('page_account_profile.label_name')}
            </Typography>
            <Typography component="p" variant="p">
              {user.first_name} {user.last_name}
            </Typography>
            <Typography component="p" variant="smalllabel">
              {t('page_account_profile.label_email')}
            </Typography>
            <Typography variant="body1">
              {user.email}
            </Typography>
            <Typography component="p" variant="smalllabel">
              {t('page_account_profile.label_address')}
            </Typography>
            {user.defaultAddress && (
              <Typography variant="body1">
                {user.defaultAddress.company && <>{user.defaultAddress.company}<br /></>}
                {user.defaultAddress.address1 && <>{user.defaultAddress.address1}<br /></>}
                {user.defaultAddress.address2 && <>{user.defaultAddress.address2}<br /></>}
                {user.defaultAddress.city && <>{user.defaultAddress.city}<br /></>}
                {user.defaultAddress.province && <>{user.defaultAddress.province}<br /></>}
                {user.defaultAddress.country && <>{user.defaultAddress.country}<br /></>}
                {user.defaultAddress.zip && <>{user.defaultAddress.zip}<br /></>}
              </Typography>
            )}
            <Typography component="p" variant="smalllabel">
              {t('page_account_profile.label_phone')}
            </Typography>
            <Typography variant="body1">
              {user.phone ? user.phone : <>Not set</>}
            </Typography>
          </Stack>
        </Paper>
        <Typography component="h2" variant="h2" sx={{ pt: 2 }}>{t('page_account_items.text_title')}</Typography>
        <Paper sx={{ pt: 4, pb: 2, pl: 2, pr: 2, mb: 4 }}>
          <Stack spacing={1}>
            <Typography component="p" variant="body1">{t('page_account_items.text_content', { brand: theme.getBrandName() })}</Typography>
            <Typography component="p" variant="body1" align="right"><Link to="/app/customer-products/create/list">{t('page_account_items.link')}</Link></Typography>
          </Stack>
        </Paper>
        <Typography component="h2" variant="h2" sx={{ pt: 2 }}>{t('page_account_swaps.text_title')}</Typography>
        <Paper sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <Stack spacing={1}>
            <Typography component="p" variant="body1">{t('page_account_swaps.text_content', { brand: theme.getBrandName() })}</Typography>
            <Typography component="p" variant="body1" align="right"><Link to="/app/offers">{t('page_account_items.link')}</Link></Typography>
          </Stack>
        </Paper>
        <Button variant="contained" fullWidth onClick={handleLogout}>{t('page_account.btn_signout')}</Button>
      </Stack>
    </Container>
  );
}
