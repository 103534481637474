import React, { useState } from 'react';

// MUI
import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// Helpers
import useImageValidator from '../hooks/ImageValidator';

export default function Image({ source, alt, settings, cb }) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [setImg, hasError] = useImageValidator();
  const styles = settings || {};

  const errorStyles = {
    "marginTop": "2rem",
    "marginBottom": "2rem",
    ...styles
  }

  const loadedStyles = {
    "opacity":  "1",
    "transition": "opacity 250ms ease-in-out",
    ...styles
  }

  const unloadedStyles = {
    "opacity":  "0",
    "transition": "opacity 250ms ease-in-out",
    ...styles
  }

  const missingImage = <>
    <div style={errorStyles}>
      <Typography variant="body1" align="center">Image not found</Typography>
    </div>
  </>

  if (hasError) {
    cb();
    return missingImage;
  }

  return (
    <>
      {!isLoaded && (
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
      )}
      <img
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsLoaded(true)}
        ref={setImg}
        src={source}
        alt={alt}
        style={isLoaded ? loadedStyles : unloadedStyles} />
    </>
  );
}