import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { useApp } from '../../context/AppContext';

// MUI
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

// Custom UI
import ActionBar from '../../components/ActionBar';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import Progress from '../../components/Progress';

// Helpers
import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";

const LeftImage = styled(Paper)`
  position: relative;
  top: 12px;
  left: 2%;
  width: 160px;
  height: 240px;
  transform: rotate(-6deg);
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border: solid 8px #fff;
`

const RightImage = styled(Paper)`
  position: absolute;
  top: 40px;
  right: 8%;
  width: 160px;
  height: 240px;
  transform: rotate(6deg);
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border: solid 8px #fff;
`

export default function SwapSuccessPage(props) {

  const { t } = useTranslation();
  const { setProgression, setShowProgression } = useApp();

  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const [offeredProduct, setOfferedProduct] = useState();
  const [wantedProduct, setWantedProduct] = useState();

  let steps;

  const particlesInit = useCallback(async (engine) => {
    await loadConfettiPreset(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    //await console.log(container);
  }, []);

  const particleConfig = {
    particles: {
      color: {
        value: [theme.palette.primary.main, theme.palette.secondary.main],
      },
    },
    preset: "confetti",
  }

  const actions = [
    {
      primary: true,
      title: t("page_offer_success.btn_another"),
      onClick: () => {
        history.replace({
          pathname: '/app/swaps/search/page/1'
        })
      }
    },
    {
      title: t("page_offer_success.btn_home"),
      onClick: () => {
        history.replace({
          pathname: '/app/welcome'
        })
      }
    }
  ]

  useEffect(() => {
    if (location.state?.offeredProduct) {
      steps = [
        {
          name: t('steps_swap_request.step1'),
          completed: true,
          selected: false
        },
        {
          name: t('steps_swap_request.step2'),
          completed: true,
          selected: false
        },
        {
          name: t('steps_swap_request.step3'),
          completed: true,
          selected: false
        },
        {
          name: t('steps_swap_request.step4'),
          completed: true,
          selected: true
        }
      ]
      setProgression(steps);
      setShowProgression(true);
      setOfferedProduct(location.state.offeredProduct);
    }
    if (location.state?.wantedProduct) {
      setWantedProduct(location.state.wantedProduct);
    }
  }, [setOfferedProduct, setWantedProduct, location]);

  return (
    <>
      <Progress />
      <PageContainer>
        <PageTitle>
          {t("page_offer_success.text_title_offer")}
        </PageTitle>
        <Box sx={{ flex: 1, textAlign: 'center', flexDirection: 'column' }}>
          <Box sx={{ m: 2 }} maxWidth="md" >
            <Typography variant="body1">{t("page_offer_success.text_content_offer")}</Typography>
          </Box>
          {offeredProduct && wantedProduct && (
            <Box sx={{ position: 'relative', p: 2, mb: 6, ml: 'auto', mr: 'auto', maxWidth: 300}} maxWidth="xs">
              <LeftImage>
                <img src={offeredProduct.images[0]} alt="offered product" style={{ maxWidth: '200px', height: '100%' }} />
              </LeftImage>
              <RightImage>
                <img src={wantedProduct.images[0]} alt="wanted product" style={{ maxWidth: '200px', height: '100%' }} />
              </RightImage>
            </Box>
          )}
          <ActionBar actions={actions} />
        </Box>
      </PageContainer>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleConfig}
      />
    </>
  );
}
