import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

// Custom UI
import PageContainer from '../../components/PageContainer';
import ProductCard from '../products/components/ProductCard';
import Popup from '../../dialog/Popup';
import ActionBar from '../../components/ActionBar';
import OfferSwapDetails from './components/OfferSwapDetails';

// Helpers
import useFetch from 'use-http';
import { formatDistanceToNowStrict } from 'date-fns'

// Dialogs
import { useDialog } from "../../dialog/DialogProvider";

// Context
import { useAuth } from '../../context/AuthContext';

// Assets
import SwapImageAsset from '../../images/Swap.svg';

const Item = styled(Container)(({ theme }) => ({
  padding: '1rem'
}));

const swapIconPlacementStyle = {
  display: 'flex',
  position: 'relative',
  top: '-2rem',
  marginBottom: '-2rem',
  justifyContent: 'center'
}

const swapIconStyle = {
  filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5))'
}

export default function OfferPage() {

  const { t } = useTranslation();

  const { user } = useAuth();
  const history = useHistory();
  const params = useParams();
  const [offer, setOffer] = useState();
  const { get, post, response } = useFetch(`offers/${params.offerId}`);
  const actions = [];

  useEffect(() => {
    async function initializeOffer() {
      const offer = await get();

      if (response.ok) {
        setOffer(offer);
      }
    }

    initializeOffer();
  }, [get, response])

  const [openDialog, closeDialog] = useDialog();
  const onOpenDialog = (details) => {
    openDialog({
      children: (
        <Popup details={details} />
      )
    });
  };

  const popupAcceptDetails = {
    buttonStyle: "contained",
    popupTitle: t('swap_offer_dialog_accept.title'),
    headerText: t('swap_offer_dialog_accept.heading'),
    bodyText: t('swap_offer_dialog_accept.text'),
    proceedTitle: t('swap_offer_dialog_accept.btn_action'),
    showCancel: true,
    proceedHandler: async () => {
      await post('accept', {})
      closeDialog();
      history.push({
        pathname: '/app/offers'
      })
    },
    closeHandler: () => {
      closeDialog();
    }
  }

  const popupDeclineDetails = {
    buttonStyle: "contained",
    popupTitle: t('swap_offer_dialog_decline.title'),
    headerText: t('swap_offer_dialog_decline.heading'),
    bodyText: t('swap_offer_dialog_decline.text'),
    proceedTitle: t('swap_offer_dialog_decline.btn_action'),
    showCancel: true,
    proceedHandler: async () => {
      await post('decline', {})
      closeDialog();
      history.push({
        pathname: '/app/offers'
      })
    },
    closeHandler: () => {
      closeDialog();
    }
  }

  const popupCancelDetails = {
    buttonStyle: "contained",
    popupTitle: t('swap_offer_dialog_cancel.title'),
    headerText: t('swap_offer_dialog_cancel.heading'),
    bodyText: t('swap_offer_dialog_cancel.text'),
    proceedTitle: t('swap_offer_dialog_cancel.btn_action'),
    showCancel: true,
    proceedHandler: async () => {
      await post('cancel', {})
      closeDialog();
      history.push({
        pathname: '/app/offers'
      })
    },
    closeHandler: () => {
      closeDialog();
    }
  }

  const getTitle = (offer) => {
    if (user.username === offer.customer_id) {
      switch (offer.status) {
        case 'offered':
          return t('swap_offer_status_offered.text_title_offered');
        case 'accepted':
          return t('swap_offer_status_offered.text_title_accepted');
        case 'rejected':
          return t('swap_offer_status_offered.text_title_rejected');
        case 'cancelled':
          return t('swap_offer_status_offered.text_title_cancelled');
        default:
          return t('swap_offer_status_offered.text_title_unknown');
      }
    } else {
      switch (offer.status) {
        case 'offered':
          return t('swap_offer_status_recieved.text_title_offered');
        case 'accepted':
          return t('swap_offer_status_recieved.text_title_accepted');
        case 'rejected':
          return t('swap_offer_status_recieved.text_title_rejected');
        case 'cancelled':
          return t('swap_offer_status_recieved.text_title_cancelled');
        default:
          return t('swap_offer_status_recieved.text_title_unknown');
      }
    }
  }

  const getSubTitle = (offer) => {
    if (user.username === offer.customer_id) {
      switch (offer.status) {
        case 'offered':
          return t('swap_offer_status_offered.text_body_offered');
        case 'accepted':
          return t('swap_offer_status_offered.text_body_accepted');
        case 'rejected':
          return t('swap_offer_status_offered.text_body_declined', formatDistanceToNowStrict(offer.accepted_at));
        case 'cancelled':
          return t('swap_offer_status_offered.text_body_cancelled');
        default:
          return t('swap_offer_status_offered.text_body_unknown');
      }
    } else {
      switch (offer.status) {
        case 'offered':
          return t('swap_offer_status_recieved.text_body_offered');
        case 'accepted':
          return t('swap_offer_status_recieved.text_body_accepted', formatDistanceToNowStrict(offer.accepted_at));
        case 'rejected':
          return t('swap_offer_status_recieved.text_body_declined', formatDistanceToNowStrict(offer.declined_at));
        case 'cancelled':
          return t('swap_offer_status_recieved.text_body_cancelled', formatDistanceToNowStrict(offer.cancelled_at));
        default:
          return t('swap_offer_status_recieved.text_body_unknown');
      }
    }
  }

  if (user && offer) {
    if (offer.status !== 'offered') {
      actions.push({
        disabled: true,
        title: offer.status,
        onClick: () => onOpenDialog(popupAcceptDetails)
      })
    } else {
      if (user.username === offer.wanted_customer_id) {
        actions.push({
          primary: true,
          title: t("swap_offer_receive.btn_offered_accept"),
          onClick: () => onOpenDialog(popupAcceptDetails)
        })
        actions.push({
          title: t("swap_offer_receive.btn_offered_decline"),
          onClick: () => onOpenDialog(popupDeclineDetails)
        });
      } else {
        actions.push({
          primary: true,
          title: t("swap_offer_receive.btn_offered_cancel"),
          onClick: () => onOpenDialog(popupCancelDetails)
        })
      }
    }
  }

  return (
    <PageContainer>
      {offer && (
        <Stack spacing={2} sx={{ mb: 4 }}>

          <Typography component="h2" variant="h2">{getTitle(offer)}</Typography>

          {offer.status !== 'accepted' && (
            <Typography component="p" variant="p">{getSubTitle(offer)}</Typography>
          )}

          {offer.status === 'accepted' && (
            <OfferSwapDetails offer={offer} isOfferer={user.username === offer.customer_id} />
          )}

          <Box sx={{ mt: 2 }}>
            <Paper elevation={8}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Item>
                  <Box sx={{display: 'flex', justifyContent: 'center' }}>
                    <Chip label={t("swap_offer_receive.badge_customer")} color="primary" variant="outlined" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
                  </Box>
                </Item>
                <Item>
                  <ProductCard product={user.username === offer.customer_id ? offer.offered_product : offer.wanted_product} />
                </Item>
              </Box>
            </Paper>

            <Paper elevation={8}>
              <Box style={swapIconPlacementStyle}><img src={SwapImageAsset} width='48' style={swapIconStyle} alt="" /></Box>
              <Box>
                <Item>
                  <Box sx={{display: 'flex', justifyContent: 'center' }}>
                    <Chip label={t("swap_offer_receive.badge_swap_product")}  variant="outlined" color="primary" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
                  </Box>
                </Item>
                <Item>
                  <ProductCard product={user.username === offer.customer_id ? offer.wanted_product : offer.offered_product} />
                </Item>
              </Box>
            </Paper>
          </Box>

        </Stack>
      )}
      <ActionBar actions={actions} />
    </PageContainer>
  );
}
