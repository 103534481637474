import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { useApp } from '../../context/AppContext';

// MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// Custom UI
import ActionBar from '../../components/ActionBar';
import ProductRow from '../products/components/ProductRow';
import Progress from '../../components/Progress';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import Alert from '../../components/Alert';
import Spinner from '../../components/Spinner';

// Helpers
import useFetch from 'use-http';

const buttonStyle = {
  minWidth: 200,
};

export default function ItemsPage() {

  const { setProgression, setShowProgression } = useApp();

  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const [products, setProducts] = useState([]);
  const [wantedProduct, setWantedProduct] = useState();


  useEffect(() => {
    const steps = [
      {
        name: t('steps_swap_request.step1'),
        completed: true,
        selected: false
      },
      {
        name: t('steps_swap_request.step2'),
        completed: false,
        selected: true
      },
      {
        name: t('steps_swap_request.step3'),
        completed: false,
        selected: false
      },
      {
        name: t('steps_swap_request.step4'),
        completed: false,
        selected: false
      },
    ]

    setProgression(steps);
    setShowProgression(true);
  }, [t, setProgression, setShowProgression])

  useEffect(() => {
    if (location.state?.wantedProduct) {
      setWantedProduct(location.state.wantedProduct)
    }
  }, [setWantedProduct, location]);

  const { post, response, loading, error } = useFetch(`customer-products`);

  useEffect(() => {
    async function initializeSwaps() {
      const products = await post('search', {});

      if (response.ok) {
        console.log('Setting products', products);
        setProducts(products.records);
      }
    }

    initializeSwaps();
  }, [post, response]);

  const handleSelectProduct = (product) => {
    if (product.is_listed) {
      history.push({
        pathname: 'request',
        state: { wantedProduct, offeredProduct: product }
      })
    } else {
      handleEditProduct(product);
    }
  }

  const handleEditProduct = (product) => {
    history.push({
      pathname: 'update',
      state: { product, wantedProduct }
    })
  }

  const actions = [{
    title: 'Back',
    onClick: () => {
      history.goBack();
    }
  }]

  return (
    <>
      <Progress />
      <PageContainer>
        <PageTitle settings={{size:'h2'}}>{t('page_customer_products_swap.text_title')}</PageTitle>

        <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
          { loading &&
            <Spinner />
          }
          { !loading && !error && products.length &&
            <Box>
              {
                products.length ? products.map((product) => (
                  <ProductRow key={product.id} product={product} actions={(
                    <>
                      <Button variant="contained" size="small" style={buttonStyle} onClick={() => handleSelectProduct(product)}>{t('page_customer_products_swap.btn_offer')}</Button>
                    </>
                  )} />
                )) : <Alert severity="info">{t('page_customer_products_swap.text_error_no_listed_items')}</Alert>
              }
            </Box>
          }
          { !loading && error &&
            <Alert severity="error">{t('global.text_error')}</Alert>
          }
        </Paper>
      </PageContainer>

      <PageContainer>
        <Box sx={{ m: 2}}>
          <Typography sx={{ mb: 2 }}  align='center'>
            {t('page_customer_products_swap.text_item_missing')} <Link to="/app/customer-products/create/select/page/1">{t('page_customer_products_swap.link_add_item')}</Link>
          </Typography>
          <ActionBar actions={actions} />
        </Box>
      </PageContainer>
    </>
  );
}
