import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Custom UI
import PageContainer from '../../components/PageContainer';
import TokenTrackingDetails from './components/TokenTrackingDetails';
import ActionBar from '../../components/ActionBar';
// import TokenSwapDetails from './components/TokenSwapDetails';

// Helpers
import useFetch from 'use-http';

// Dialogs
import { useDialog } from "../../dialog/DialogProvider";

// Context
import { useAuth } from '../../context/AuthContext';

// Assets
import SwapImageAsset from '../../images/Swap.svg';

const Item = styled(Container)(({ theme }) => ({
  padding: '1rem'
}));

const swapIconPlacementStyle = {
  display: 'flex',
  position: 'relative',
  top: '-2rem',
  marginBottom: '-2rem',
  justifyContent: 'center'
}

const swapIconStyle = {
  filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5))'
}

export default function TokenPage() {

  const { t } = useTranslation();

  const { user } = useAuth();
  const history = useHistory();
  const params = useParams();
  const [token, setToken] = useState();
  const { get, post, response } = useFetch('tokens');
  const actions = [];

  useEffect(() => {
    async function initializeToken() {
      const token = await get(params.tokenId);

      if (response.ok) {
        setToken(token);
      }
    }

    initializeToken();
  }, [get, response])

  return (
    <PageContainer>
      {token && (
        <Stack spacing={2} sx={{ mb: 4 }}>
          <Typography textAlign="center" component="h2" variant="h2">{t('page_token.text_title')}</Typography>
          <Box textAlign="center">
            <Chip label={t(`page_token.text_title_${token.status}`)} color="primary" variant="outlined" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px', maxWidth: '300px' }} />
          </Box>

          <Typography textAlign="center" component="p" variant="p">
            {t(`page_token.text_info_${token.status}`)}
          </Typography>

          { token.tracking && <TokenTrackingDetails token={token} />}

          <Typography component="p" variant="h3">{t('page_token.text_from_address')}</Typography>
          <Paper elevation={3}>
            <Box sx={{ mt: 2, mb: 2, p: 1 }}>
              <Typography component="p" variant="p">{token.ship_from.name}</Typography>
              <Typography component="p" variant="p">{token.ship_from.company}</Typography>
              <Typography component="p" variant="p">{token.ship_from.address1}</Typography>
              <Typography component="p" variant="p">{token.ship_from.address2}</Typography>
              <Typography component="p" variant="p">{token.ship_from.city}</Typography>
              <Typography component="p" variant="p">{token.ship_from.province}</Typography>
              <Typography component="p" variant="p">{token.ship_from.zip}</Typography>
            </Box>
          </Paper>

          <Typography component="p" variant="h3">{t('page_token.text_to_address')}</Typography>
          <Paper elevation={3}>
            <Box sx={{ mt: 2, mb: 2, p: 1 }}>
              <Typography component="p" variant="p">{token.ship_to.name}</Typography>
              <Typography component="p" variant="p">{token.ship_to.company}</Typography>
              <Typography component="p" variant="p">{token.ship_to.address1}</Typography>
              <Typography component="p" variant="p">{token.ship_to.address2}</Typography>
              <Typography component="p" variant="p">{token.ship_to.city}</Typography>
              <Typography component="p" variant="p">{token.ship_to.province}</Typography>
              <Typography component="p" variant="p">{token.ship_to.zip}</Typography>
            </Box>
          </Paper>

{/*
          <Box sx={{ mt: 2 }}>
            <Paper elevation={8}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Item>
                  <Box sx={{display: 'flex', justifyContent: 'center' }}>
                    <Chip label={t("swap_token_receive.badge_customer")} color="primary" variant="outlined" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
                  </Box>
                </Item>
                <Item>
                  <ProductCard product={user.username === token.customer_id ? token.tokened_product : token.wanted_product} />
                </Item>
              </Box>
            </Paper>

            <Paper elevation={8}>
              <Box style={swapIconPlacementStyle}><img src={SwapImageAsset} width='48' style={swapIconStyle} alt="" /></Box>
              <Box>
                <Item>
                  <Box sx={{display: 'flex', justifyContent: 'center' }}>
                    <Chip label={t("swap_token_receive.badge_swap_product")}  variant="outlined" color="primary" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
                  </Box>
                </Item>
                <Item>
                  <ProductCard product={user.username === token.customer_id ? token.wanted_product : token.tokened_product} />
                </Item>
              </Box>
            </Paper>
          </Box> */}

        </Stack>
      )}
      <ActionBar actions={actions} />
    </PageContainer>
  );
}
