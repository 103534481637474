import React, { useState, createContext, useContext } from 'react';

// The context held in this context file are general UI and shared across
// many components.

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {

  const [progression, setProgression] = useState([]);
  const [showProgression, setShowProgression] = useState(false);

  return (
    <AppContext.Provider value={{
      setProgression,
      progression,
      setShowProgression,
      showProgression
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);