import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

// Context
import { useAuth } from '../context/AuthContext';

// Assets

export default function MyProduct({ product }) {

  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  if (product?.is_mine && isAuthenticated) {
    return (
      <Box style={{position: 'absolute', top: "1rem", right: "1rem"}}>
        <Chip label={t("product_cell.label_mine")} color="primary" size="small" />
      </Box>
    );
  }

  return (
    <></>
  );
}