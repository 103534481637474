import React, { useState, createContext, useContext } from 'react';

// MUI
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

// Create the context
const LoadingContext = createContext(null);

export const LoadingProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false)

  return (
    // Using the provider so that ANY component in our application can
    // use the values that we are sending.
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

// Finally creating the custom hook
export const useLoading = () => useContext(LoadingContext);

export function LoadingProgressBar() {

  const { isLoading } = useLoading()

  return (
    <Box sx={{ position: 'absolute', width: '100%', top: { xxs: 56, sm: 64 } }}>
      {isLoading && <LinearProgress />}
    </Box>
  )
}