import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

// Extras
import packageJson from '../../package.json';

// Context
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../theme/ThemeContext';
import { Typography } from '@mui/material';

export default function Menu() {

  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { logout, isAuthenticated } = useAuth();
  const [open, setOpen] = useState(false);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleHome = () => {
    setOpen(false);
    history.push({
      pathname: '/app/welcome',
    });
  }

  const handleLogin = () => {
    setOpen(false);
    history.push({
      pathname: '/auth/login',
    });
  }

  const handleMyItems = () => {
    setOpen(false);
    history.push({
      pathname: '/app/customer-products/create/list',
    });
  }

  const handleListedItems = () => {
    setOpen(false);
    history.push({
      pathname: '/app/swaps/search/page/1',
    });
  }

  const handleOffers = () => {
    setOpen(false);
    history.push({
      pathname: '/app/offers',
    });
  }

  const handleTokens = () => {
    setOpen(false);
    history.push({
      pathname: '/app/tokens',
    });
  }

  const handleHowItWorks = () => {
    setOpen(false);
    if (isAuthenticated) {
      history.push({
        pathname: `/app/how-it-works`,
      });
    } else {
      history.push({
        pathname: `/landing/how-it-works`,
      });
    }
  }

  const handleUnfolded = () => {
    window.location.href='https://thisisunfolded.com/';
  };

  const handleLogout = async () => {
    await logout();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        id="basicButton"
        aria-controls="basicMenu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: 'none' }) }}
        color="primary_dark">
        <MenuIcon />
      </IconButton>
      <Drawer
        sx={{
          width: '320',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 320,
          },
        }}
        anchor="left"
        open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </DrawerHeader>
          <List>
            { isAuthenticated && <ListItem onClick={handleHome}><ListItemText color="primary">{t('navigation.link_home')}</ListItemText></ListItem> }
            { isAuthenticated && <Divider /> }

            { isAuthenticated && <ListItem onClick={handleListedItems}><ListItemText>{t('navigation.link_listed_items')}</ListItemText></ListItem> }
            { isAuthenticated && <ListItem onClick={handleMyItems}><ListItemText>{t('navigation.link_customer_items')}</ListItemText></ListItem> }
            { isAuthenticated && <ListItem onClick={handleOffers}><ListItemText>{t('navigation.link_offers')}</ListItemText></ListItem> }
            { isAuthenticated && <ListItem onClick={handleTokens}><ListItemText>{t('navigation.link_tokens')}</ListItemText></ListItem> }

            <ListItem onClick={handleHowItWorks}><ListItemText>{t('navigation.link_how_it_works')}</ListItemText></ListItem>

            { isAuthenticated ? <ListItem onClick={handleLogout}><ListItemText>{t('navigation.link_signout')}</ListItemText></ListItem> : <ListItem onClick={handleLogin}><ListItemText>{t('navigation.link_signin')}</ListItemText></ListItem> }

            <Divider />

            {/* <ListItem onClick={handleUnfolded}><ListItemText>{t('navigation.link_unfolded', { brand: theme.getBrandName() })}</ListItemText></ListItem> */}

            <ListItem>
              <ListItemText>
                <Box sx={{ mt: 4 }}>
                  <Typography component="p" variant='body2'>{t('navigation.text_version')} { packageJson.version }</Typography>
                </Box>
              </ListItemText>
            </ListItem>

        </List>
      </Drawer>
    </>
  );
}
