import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default function PageContainerWide({children}) {
  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Stack>
        {children}
      </Stack>
    </Container>
  );
}
