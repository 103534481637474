import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Custom UI
import ProductCarousel from '../../products/components/ProductCarousel';

// Hooks & Helpers
import { useFetch } from 'use-http';
import useMappedValues from '../../../hooks/MappedValues';

export default function SwapProductDetails({ product }) {

  const { t } = useTranslation();
  const { get } = useMappedValues();

  const [currentProduct, setCurrentProduct] = useState();

  const { get: fetchGet, response: fetchResponse } = useFetch('customer-products');

  useEffect(() => {
    async function getProductDetails() {
      if (!product.id) return;
      const loadedProduct = await fetchGet(product.id)

      if (fetchResponse.ok) {
        console.log("PDP Loaded Product", product);
        setCurrentProduct(loadedProduct);
      }
    }

    getProductDetails()
  }, [get, fetchResponse, product.productId])

  return (
    <Stack spacing={1} sx={{ pb: 2 }}>
      <ProductCarousel product={product} />
      <Divider />
      <Stack spacing={1} sx={{ p: 2 }}>

        <Typography variant="body2">{t('pdp.title_reason_for_swap')}</Typography>
        <Typography variant="body1">
        {currentProduct?.reason ?
          t(get('reason', currentProduct.reason))
        :
          t('pdp.text_no_reason')
        }
        </Typography>

        <Typography variant="body2">{t('pdp.title_condition')}</Typography>
        <Typography variant="body1">
        {currentProduct?.condition ?
          t(get('condition', currentProduct.condition))
        :
          t('pdp.text_no_condition')
        }
        </Typography>

        <Typography variant="body2">{t('pdp.title_notes')}</Typography>
        <Typography variant="body1">
        {currentProduct?.notes ?
          currentProduct.notes
        :
          t('pdp.text_no_notes')
        }
        </Typography>
      </Stack>
    </Stack>
  )
}