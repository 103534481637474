import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Contexts
import { useApp } from '../../context/AppContext';

// MUI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

// Custom UI
import ActionBar from '../../components/ActionBar';
import SwapProductDetails from './components/SwapProductDetails';
import RawHTML from '../../components/RawHTML';
import Progress from '../../components/Progress';
import PageTitle from '../../components/PageTitle';
import Sizes from '../../components/Sizes';
import Care from '../../components/Care';

// Helpers
import useFetch from 'use-http';

// Dialogs
import { useDialog } from "../../dialog/DialogProvider";
import Popup from '../../dialog/Popup';

const accordionContentStyle = {
  margin: '0 1rem 1rem 1rem',
  padding: '2rem',
  backgroundColor: 'var(--color-panel-one)'
}

export default function SwapViewProductPage(props) {

  const { t } = useTranslation();

  const { setProgression, setShowProgression } = useApp();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [offeredProduct, setOfferedProduct] = useState();
  const [wantedProduct, setWantedProduct] = useState();
  const [meta, setMeta] = useState([]);
  const { get, post, del, response, loading } = useFetch('customer-products');

  const [openDialog, closeDialog] = useDialog();

  const onOpenDialog = (details) => {
    openDialog({
      children: (
        <Popup details={details} />
      )
    });
  };

  const popupDeleteProduct = {
    buttonStyle: "contained",
    popupTitle: t('dialog_delete_item.title'),
    bodyText: t('dialog_delete_item.text'),
    proceedTitle: t('dialog_delete_item.btn_action'),
    cancelTitle: t('dialog_delete_item.btn_cancel'),
    showCancel: true,
    proceedHandler: () => {
      console.log("Deleting product", params.customerProductId);
      handleDeleteProduct();
      closeDialog();
    },
    closeHandler: () => {
      closeDialog();
    }
  }

  const steps = [
    {
      name: t('steps_swap_request.step1'),
      completed: false,
      selected: true
    },
    {
      name: t('steps_swap_request.step2'),
      completed: false,
      selected: false
    },
    {
      name: t('steps_swap_request.step3'),
      completed: false,
      selected: false
    },
    {
      name: t('steps_swap_request.step4'),
      completed: false,
      selected: false
    },
  ]

  useEffect(() => {
    setProgression(steps);
    setShowProgression(true);
  }, []);

  useEffect(() => {
    async function getProduct() {
      if (!loading) {
        if (params.customerProductId) {
          const product = await get(params.customerProductId);
          if (response.ok) {
            setWantedProduct(product);
            setMeta(product?.metafields);
            console.log("[SwapViewProductPage] Fetched", product)
          }
        }
      }
    }

    if (location.state?.offeredProduct) {
      setOfferedProduct(location.state.offeredProduct);
    }

    if (location.state?.wantedProduct) {
      console.log("[SwapViewProductPage] Wanted product in state, setting...", location.state.wantedProduct)
      setWantedProduct(location.state.wantedProduct);
      setMeta(location.state.wantedProduct?.metafields);
    } else if (!wantedProduct) {
      console.log("[SwapViewProductPage] No wanted product, fetching...")
      getProduct();
    }

  }, [setOfferedProduct, setWantedProduct, loading, wantedProduct, location, get, response, params]);

  const showDeleteProductDialog = () => {
    onOpenDialog(popupDeleteProduct);
  }

  const handleDelistProduct = async () => {
    console.log('[SwapViewProductPage]: Delisting product', params.customerProductId)
    await post(`${params.customerProductId}/delist`, {});

    if (response.ok) {
      console.log('[SwapViewProductPage]: Successfully delisted product', params.customerProductId);
      const currentWantedProduct = wantedProduct;
      currentWantedProduct.is_mine = false;
      setWantedProduct(currentWantedProduct);
    }
  }

  const handleDeleteProduct = async () => {
    console.log('[SwapViewProductPage]: Deleting product', params.customerProductId);
    await del(`${params.customerProductId}`);

    if (response.ok) {
      console.log('[SwapViewProductPage]: Successfully deleted product', params.customerProductId);
      history.replace('/app/customer-products/create/list');
    }
  }

  let actions = [];

  if (wantedProduct?.is_mine) {
    actions = [
      {
        title: t('pdp.btn_unlist'),
        onClick: () => {
          handleDelistProduct();
        }
      },
      {
        title: t('pdp.accordion_btn_back'),
        onClick: () => {
          history.goBack();
        }
      }
    ]
  } else if (wantedProduct?.is_offered) {
    actions = [
      {
        title: t('pdp.accordion_btn_back'),
        onClick: () => {
          history.goBack();
        }
      }
    ]
  } else {
    actions = [
      {
        primary: true,
        title: t('pdp.accordion_btn_swap'),
        onClick: () => {
          if (offeredProduct) {
            history.push({
              pathname: '../request',
              state: { wantedProduct, offeredProduct }
            })
          } else {
            history.push({
              pathname: '../offer',
              state: { wantedProduct }
            })
          }
        }
      },
      {
        title: t('pdp.accordion_btn_back'),
        onClick: () => {
          history.goBack();
        }
      }
    ]
  }

  let title;

  if (wantedProduct?.is_mine) {
    title = <PageTitle settings={{size:'h2'}}>{t('pdp.title_mine')}</PageTitle>
  }

  if (!wantedProduct?.is_mine) {
    title = <PageTitle settings={{size:'h2'}}>{t('pdp.title_swap_for')}</PageTitle>
  }

  if (wantedProduct?.is_offered) {
    title = <PageTitle settings={{size:'h2'}}>{t('pdp.title_offered')}</PageTitle>
  }

  if (!wantedProduct) {
    return null;
  }

  return (
    <>
      {!wantedProduct?.is_mine && !wantedProduct?.is_offered &&
        <Progress />
      }
      <Container maxWidth="md" disableGutters>

        {title}

        <SwapProductDetails product={wantedProduct} />

        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('pdp_details.accordion_title_materials')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={accordionContentStyle}>
            <RawHTML html={ meta.find((item) => item.key === 'materials')?.value } />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('pdp_details.accordion_title_fit')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={accordionContentStyle}>
            <RawHTML html={ meta.find((item) => item.key === 'fit')?.value } />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('pdp_details.accordion_title_size')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={accordionContentStyle}>
            <Sizes product={wantedProduct}/>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('pdp_details.accordion_title_care')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={accordionContentStyle}>
            <Care product={wantedProduct}/>
          </AccordionDetails>
        </Accordion>

        <ActionBar actions={actions} />

        {wantedProduct?.is_mine &&
          <Box sx={{ textAlign: 'center'}}>
            <Link onClick={() => showDeleteProductDialog()} underline="always">{t('pdp.link_delete')}</Link>
          </Box>
        }

      </Container>
    </>
  );
}
