import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ConfirmPage() {

  const location = useLocation()
  const params = new URLSearchParams(location.hash.substring(1));
  console.log('JWT Token', params.get('id_token'));
  localStorage.setItem('id_token', params.get('id_token'))

  return (
    <><h1>Confirmed</h1></>
  );
}
