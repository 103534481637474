import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Context
import { useAuth } from '@/context/AuthContext';

const LeftImage = styled(Paper)(() => ({
  position: 'absolute',
  top: '0px',
  left: '0%',
  height: '90px',
  transform: 'rotate(-6deg)',
  border: 'solid 8px #fff',
}));

const RightImage = styled(Paper)(() => ({
  position: 'absolute',
  top: '0px',
  right: '0%',
  height: '90px',
  transform: 'rotate(6deg)',
  border: 'solid 8px #fff',
}));

const getOfferStatusColor = (status) => {
  let color
  switch (status) {
    case 'offered':
      color = 'secondary'
      break
    case 'accepted':
      color = 'success'
      break
    case 'cancelled':
    case 'declined':
      color = 'error'
      break
    default:
      color = 'warning'
  }
  return color;
}

export default function OfferListItem({ offer, onClick }) {

  const { t } = useTranslation();
  const { user } = useAuth();

  const myProduct = user.username === offer.customer_id ? offer.offered_product : offer.wanted_product
  const theirProduct = user.username === offer.customer_id ? offer.wanted_product : offer.offered_product

  return offer && (
    <Box onClick={onClick} sx={{ p:2, cursor: "pointer", borderBottom: '1px solid #ccc' }}>
      <Box textAlign="right">
        <Chip color={getOfferStatusColor(offer.status)} label={offer.status} variant="outline" size="small"/>
      </Box>

      <Stack direction="row" spacing={2} sx={{ pl: 1, pr: 1 }}>
        <Box sx={{ position: 'relative', width: 120, height: 120, mt: 3, p: 1 }}>
          <LeftImage>
            <img src={`${myProduct.images[0]}?crop=center&height=200`} loading="lazy" alt={myProduct.name} style={{ maxWidth: '90px', height: '100%' }} />
          </LeftImage>
          <RightImage>
            <img src={`${theirProduct.images[0]}?crop=center&height=200`} loading="lazy" alt={theirProduct.name} style={{ maxWidth: '90px', height: '100%' }} />
          </RightImage>
        </Box>
        <Stack spacing={0} sx={{ flex: 1, p: 1 }}>
          <Box sx={{mb: 2}}>
            <Stack>
              <Typography variant="body1_bold" sx={{ flex: 1 }}>{t("page_offers.label_your")}</Typography>
            </Stack>
            <Typography variant="body1">{myProduct.name}</Typography>
            <Typography variant="body2">{t("page_offers.label_size")} {myProduct.variant.name}</Typography>
          </Box>
          <Box>
            <Typography variant="body1_bold">{t("page_offers.label_their")}</Typography>
            <Typography variant="body1">{theirProduct.name}</Typography>
            <Typography variant="body2">{t("page_offers.label_size")} {theirProduct.variant.name}</Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}
