import React from 'react';

// MUI
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

// Custom UI
import Banner from './Banner';

const MastheadBox = styled(Paper)(({ theme }) => ({
  margin: "10rem auto 0",
  padding: "2rem 3rem",
  backgroundColor: theme.palette.background.paper,
  maxWidth: "500px"
}));

export default function PageMasthead({children}) {
  return (
    <>
      <Banner />
      <MastheadBox elevation={0} sx={{ mt: '10rem', pt: 3, pr: 4, pb: 3, pl: 4 }}>
        <Stack spacing={2}>
          {children}
        </Stack>
      </MastheadBox>
    </>
  );
}
