import React from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function PageTitle({settings, children}) {

  const size = settings?.size || 'h1';

  return (
    <Box sx={{ mt: 6, mb: 4}}>
      <Typography component="h1" variant={size} align='center'>
        {children}
      </Typography>
    </Box>
  );
}
