
import React from 'react';

// Styles
import './ColorChip.scss';

export default function ColorChip({colors}) {

  const color_1 = colors?.color1 ? { backgroundColor: colors.color1 } : null;
  const color_2 = colors?.color2 ? { backgroundColor: colors.color2 } : null;

  if (!colors?.multi) {
    return (
      <span className="sp-color-chip">
        <span className="sp-color-chip__color" style={color_1}></span>
        <span className="sp-color-chip__color sp-color-chip__color--secondary" style={color_2}></span>
      </span>
    );
  }

  return (
    <span className="sp-color-chip">
      <span className="sp-color-chip__color sp-color-chip__color--multi"></span>
    </span>
  );
}
