import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Custom UI
import ProductsGrid from './ProductsGrid';
import FiltersModal from './ProductFiltersModal';
import ProductFilters from './ProductFilters';
import PageContainer from '../../../components/PageContainer';

// Helpers
import { useFetch } from 'use-http';
import useDebounce from '../../../hooks/Debounce';

export default function ProductSearch({
  onProductClick,
  type,
  title,
  subTitle,
  hideMine = false,
  showRequestSwapButton }) {

  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  const searchFromQuery = urlSearchParams.get('search');
  const filterFromQuery = urlSearchParams.get('filters');

  const searchValueFromQuery  = searchFromQuery ? decodeURIComponent(searchFromQuery) : '';
  const filterValueFromQuery = filterFromQuery ? JSON.parse(decodeURIComponent(filterFromQuery)) : [];

  const [search, setSearch] = useState(searchValueFromQuery);
  const [filters, setFilters] = useState(filterValueFromQuery);

  const [page, setPage] = useState(parseInt(params.page.split('?')[0]) || 1);

  const [count, setCount] = useState(0);
  const [products, setProducts] = useState([]);

  // Filters selected by user
  const [filterOpen, setFilterOpen] = useState(false);
  const filterControl = useRef();

  const searchDebounced = useDebounce(search, 500);

  const { post, loading } = useFetch();

  const limit = 12;

  const searchProducts = async () => {
    try {
      const response = await post(`${type}/search`, {
        search: searchDebounced,
        hideMine,
        filters,
        page,
        limit
      });
      if (response && response.records) {
        const { records, count } = response;
        console.log("SEARCH", response);
        setProducts(records);
        setCount(count);
      }
    } catch (error) {
      console.error('Failed to search products:', error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('search', searchDebounced)
    urlParams.set('filters', encodeURIComponent(JSON.stringify(filters)))
    history.replace({
      pathname: `./${page}`,
      search: urlParams.toString()
    })
  }, [searchDebounced, filters]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('search', searchDebounced)
    urlParams.set('filters', encodeURIComponent(JSON.stringify(filters)))

    console.log('Search Url params', urlParams.toString(), location.search)
    history.push({
      pathname: `./${page}`,
      search: urlParams.toString()
    })
  }, [page]);

  useEffect(() => {
    console.log('Search changed', location, params);
    setPage(parseInt(params.page));
    searchProducts();
  }, [location]);

  function handleSearchChange(e) {
    const regex = /^[a-zA-Z0-9 ]*$/;
    const inputValue = e.target.value;

    if (regex.test(inputValue)) {
      setPage(1);
      setSearch(e.target.value);
    }
  }

  const handleClickOpenFilter = () => {
    setFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setFilterOpen(false);
  };

  const handleFilterChange = async (selectedFilters) => {
    const f = [];
    for (const [name, values] of Object.entries(selectedFilters)) {
      f.push({
        name: `${name}`, values
      })
    }
    setFilters(f);
    setPage(1);
  }

  const handleFilterReset = () => {
    setFilters([]);
    setPage(1);
    setSearch('');
  }

  const handleProductClick = (product) => {
    onProductClick(product);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const filterActions = [
    {
      primary: true,
      title: t("search.btn_filter_apply"),
      onClick: () => {
        handleCloseFilter();
      }
    }, {
      title: t("search.btn_filter_clear"),
      onClick: () => {
        filterControl.current.resetFilters();
        handleCloseFilter();
      }
    }
  ]

  return (
    <>
      <PageContainer>
        <Stack spacing={2} sx={{ p: 2 }}>
          { title && <Typography component="h1" variant="h1" align='center'>{title}</Typography>}
          { subTitle && <Typography variant="body2">{subTitle}</Typography> }
          <TextField id="productSearch" name="productSearch" placeholder={t("search.label_search")} value={search} onChange={handleSearchChange} />
          <Stack direction="row" spacing={1}>
            <Button fullWidth variant={filters.length > 0 ? 'contained' : 'outlined'} onClick={handleClickOpenFilter}>
              {t("search.btn_filter")}
            </Button>
          </Stack>
        </Stack>
        <FiltersModal open={filterOpen} title={t('search.text_title_filter_dialog')} handleClose={handleCloseFilter} actions={filterActions}>
          <ProductFilters
            ref={filterControl}
            userSelectedFilters={filters}
            type={type}
            onFilterReset={handleFilterReset}
            onFilterChange={handleFilterChange} />
        </FiltersModal>
      </PageContainer>

      <Container>
        <ProductsGrid products={products} onItemClick={handleProductClick} isLoading={loading} showRequestSwapButton={showRequestSwapButton} />
        <Box display="flex" justifyContent="center" sx={{ pt: 2, pb: 2 }}>
          { products.length > 0 &&
            <Pagination
              sx={{ w: 100}}
              onChange={handlePageChange}
              count={Math.ceil(count / limit)}
              page={ page }
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          }
        </Box>
      </Container>
    </>
  )
}