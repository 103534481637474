
import React from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function Main() {

  return (
    <Grid item xs={6} sm={4} md={3} lg={3}>
      <Stack>
        <Box sx={{ position: "relative", width: "100%", aspectRatio: "3/4" }}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>
        <Skeleton variant="text" sx={{ fontSize: '12px' }} />
        <Skeleton variant="text" width={50} sx={{ fontSize: '12px' }} />
      </Stack>
    </Grid>
  );
}
