import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// Custom UI
import ProductGridCell from './ProductGridCell';
import ProductPlaceholder from './ProductPlaceholder';
import Alert from '../../../components/Alert';

function ProductsGrid({
  isLoading,
  products,
  onItemClick,
  heading,
  link,
  linktitle,
  showRequestSwapButton
}) {

  const { t } = useTranslation();
  const placeholderCount = 12;
  const handleProductClick = (product) => {
    onItemClick(product);
  }

  if ( isLoading ) {
    return (
      <Grid container spacing={1} justifyContent="center">
        {[...Array(placeholderCount)].map((p, index) => (
          <ProductPlaceholder key={index} />
        ))}
      </Grid>
    )
  }

  return (
    <>
      { products.length > 0 &&
        <Box component="span" sx={{ display: 'flex' }}>
          <Typography component="p" variant='body1' sx={{mb: 1, flex: 1}}>
            {heading}
          </Typography>
          { link && linktitle &&
            <Link href={link} underline="always">{linktitle}</Link>
          }
        </Box>
      }
      <Grid container spacing={1} justifyContent="center">
        {products.map((product, index) => (
          <Grid key={index} item xs={6} sm={4} md={3} lg={3}>
            {<ProductGridCell product={product} onClick={() => handleProductClick(product)} showRequestSwapButton={showRequestSwapButton} />}
          </Grid>
        ))}
        { !isLoading && products.length === 0 &&
          <Alert message={t('search.text_no_products')} severity="info" showPatch />
        }
      </Grid>
    </>
  );
}
export default ProductsGrid;
