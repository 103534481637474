import React, { useState, useEffect } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// Custom UI
import Spinner from './Spinner';
import Alert from './Alert';

// MUI
import Typography from '@mui/material/Typography';

// Assets
import "./Sizes.scss";

export default function Sizes({product}) {

  const { t } = useTranslation();
  const [sizeData, setSizeData ] = useState();
  const [isLoading, setIsLoading ] = useState();
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/data/sizes.json');
      const data = await response.json();
      setSizeData(data);
      generateSizeGuide(data);
    };

    if (!sizeData) {
      setIsLoading(true);
      fetchData()
        .then(() => setIsLoading(false))
        .catch(error => console.log(error));
    }
  }, [sizeData]);

  const generateSizeGuide = (data) => {

    const theProductSKU = product.variant.sku.substring(0, product.variant.sku.length - 2);
    const sizeList = [];

    data.forEach(product => {
      if (product.product_sku === theProductSKU) {
        const sizeValues = Object.entries(product)
          .filter(([key, value]) => key.includes("size_") && value !== "")
          .map(([key, value]) => ({key, size: value}));

        sizeList.push({ label: product.label, sizes: sizeValues, sku: product.product_sku });
      }
    })

    setSizes(sizeList);
  }

  return (
    <>
      { isLoading && <Spinner /> }

      { !isLoading && sizes && sizes.length > 0 &&
        <div className="sizes">
          <table className="sizes__table">
            <thead>
              <tr>
                <th></th>
                {sizes[0].sizes.map((size, index) => (
                  <th key={index}><Typography variant='body2'>{size.key.replace('size_', '')}</Typography></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sizes.map((measurement, i) => (
                <tr key={i}>
                  <td><Typography component="p" variant="p">{measurement.label}</Typography></td>
                  {measurement.sizes.map((size, j) => (
                    <td key={j} style={{whiteSpace: "nowrap"}}>
                      <Typography>{size.size}<span>cm</span></Typography>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
      { !sizes === 0 &&
        <Alert severity="warning"><Typography variant='body1'>{t('pdp_details.accordion_text_size_missing')}</Typography></Alert>
      }
    </>
  );
}
