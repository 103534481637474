import { createTheme } from '@mui/material/styles';

const bodyFont = "'canada-type-gibson', sans-serif";
const headingFont = "'canada-type-gibson', sans-serif";

export const settings = {
  displayName: 'TALLternative',
  name: 'tallternative',
  title: 'Swaps by TALLternative',
  description: 'Finally! Clothes made for tall women, by tall women! TALLternative clothing has been designed with thousands of tall shoppers so that every piece fits beautifully. Made without waste and created specifically for tall customers, discover your new favourite tall clothes online. | Shop Now from TALLternative retail',
  logo: 'Logo.svg',
  banner: 'Banner.jpg',
  shop: 'tallternative.myshopify.com',
  shopifyUrl: 'https://tallternative.co.uk',
  supportEmail: 'tallternative@support.together.supply'
};

export const customTheme = createTheme({
  typography: {
    fontFamily: bodyFont,
    color: '#000',
    h1: {
      fontFamily: headingFont,
      fontSize: 24,
      fontWeight: 'var(--font-heading-weight)',
      color: '#000',
    },
    h2: {
      fontFamily: headingFont,
      fontSize: 18,
      fontWeight: 'var(--font-heading-weight)',
      color: '#000',
    },
    h3: {
      fontFamily: headingFont,
      fontSize: 16,
      fontWeight: 'var(--font-heading-weight)',
      color: '#000',
    },
    body1: {
      fontSize: 16,
      color: '#000',
    },
    body1_bold: {
      fontSize: 16,
      color: '#000',
      fontWeight: 'var(--font-body-bold-weight)'
    },
    body2: {
      fontSize: 12,
      color: '#000',
    },
    body2_bold: {
      fontSize: 12,
      color: '#000',
      fontWeight: 'var(--font-body-bold-weight)'
    },
    body3: {
      fontSize: 16,
      color: '#000',
    },
    smalllabel: {
      fontSize: 12,
      color: '#000',
    },
    action: {
      fontFamily: bodyFont
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#2b9b9f',
    },
    primary_dark: {
      main: '#000',
    },
    secondary: {
      main: '#000',
    },
    warning: {
      main: '#000',
    },
    action: {
      fontFamily: bodyFont,
      disabledBackground: '#eef0ee',
      disabled: '#fff',
      active: '#007896',
      link: '#007896'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: bodyFont,
          fontSize: 20,
          fontWeight: 'var(--font-body-weight)'
        },
        sizeSmall: {
          fontSize: '0.8rem',
          padding: '3px 1rem',
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff'
        }
      }
    }
  }
});

