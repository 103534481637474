import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Context
import { useAuth } from '../../../context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

// Custom UI
import MySwap from '../../../components/MySwap';
import MyProduct from '../../../components/MyProduct';
import Image from "../../../components/Image";

const productImageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  cursor: "pointer"
}

export default function ProductGridCell({product, onClick, showRequestSwapButton}) {

  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const image = `${product.images[0]}?crop=center&height=400`;

  const onLoadCallback = function() {
    console.log("[ProductGridCell]: Unable to load image");
  }

  let size = '';
  if (product.variant) {
    size = `${t('product_cell.label_size')} ${product.variant?.name}`;
  }

  if (!isAuthenticated) {
    return (
      <Stack>
        <RouterLink to="/auth/login" style={{ textDecoration: 'none' }}>
          <Box sx={{ position: "relative", width: "100%", aspectRatio: "3/4" }}>
            {product.images[0] ? (
              <Image source={image} cb={onLoadCallback} alt={product.name} settings={productImageStyle} />
            ) : (
              <Skeleton variant="rectangular" />
            )}
          </Box>
          <Box sx={{ mt: 1, mb: 2 }}>
            <Typography component="p" variant="body2" noWrap>{product.name}</Typography>
            <Typography component="p" variant="body2_bold">{size}</Typography>
          </Box>
        </RouterLink>
      </Stack>
    );
  }

  let button;

  if (product?.is_mine) {
    button = <Button size="small" onClick={onClick} variant='outlined' sx={{ maxWidth: 200 }}>
      {t('product_cell.btn_view')}
    </Button>
  } else if (!product?.is_mine && !product?.is_offered) {
    button = <Button size="small" onClick={onClick}  variant='outlined' sx={{ maxWidth: 200 }}>
      {t('product_cell.btn_swap')}
    </Button>
  } else if (product?.is_offered) {
    button = <Button size="small" onClick={onClick}  variant='outlined' sx={{ maxWidth: 200 }}>
      {t('product_cell.btn_offered')}
    </Button>
  }

  return (
    <Stack sx={{ mb: 1 }}>
      <Box onClick={onClick} sx={{position: 'relative'}}>
        <MySwap product={product} />
        <MyProduct product={product} />
        <Box sx={{ overflow: "hidden", aspectRatio: "3/4" }}>
          {product.images[0] ? (
            <Image source={image} cb={onLoadCallback} alt={product.name} settings={productImageStyle} />
          ) : (
            <Skeleton variant="rectangular" />
          )}
        </Box>
        <Box sx={{ mt: 1, mb: 1 }}>
          <Typography component="p" variant="body2" noWrap>{product.name}</Typography>
          <Typography component="p" variant="body2_bold">{size}</Typography>
        </Box>
      </Box>

      {showRequestSwapButton && button }

    </Stack>
  );
}
