
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom UI
import PageContainerWide from './PageContainerWide';

// Assets
import BrandLogo from '../images/Patch.svg';

export default function Footer() {
  const history = useHistory();

  const style = {
    width: '30px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2rem',
    marginBottom: '2rem'
  }

  const handleNavigateHome = () => {
    history.replace('/app/welcome');
  }

  return (
    <PageContainerWide>
      <img style={ style } src={BrandLogo} onClick={() => handleNavigateHome()} className="Logo" alt="logo" />
    </PageContainerWide>
  );
}
