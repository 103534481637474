
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Helpers
import useFetch from 'use-http';

// MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

// Custom UI
import BasicMenu from './Menu';
import AccountButton from './AccountButton';
import NotificationsButton from './NotificationsButton';

// Contexts
import { useTheme } from '../theme/ThemeContext';
import { useAuth } from '../context/AuthContext';

const Logo = styled(Link)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "100%",
  height: "100%",
  left: "1rem",
  textAlign: "center",
  alignItems: "left",
  justifyContent: "left",
}));

export default function Navigation() {

  const { isAuthenticated } = useAuth();
  const theme = useTheme();

  const { post, response } = useFetch('notifications');
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(false);

  useEffect(() => {
    async function initializeNotifications() {
      const notifications = await post('search', {});
      if (response.ok) {
        setUnreadNotificationCount(notifications.records.reduce((prev, next) => { return !next.is_read ? prev + 1 : prev }, 0))
      }
    }

    if (isAuthenticated) {
      initializeNotifications();

      // Poll for new notifications once a minute
      const notificationTimer = setInterval(initializeNotifications, 60000);

      // If this useEffect is called again, clear the interval
      return () => clearInterval(notificationTimer);
    }

  }, [post, response, setUnreadNotificationCount, isAuthenticated]);

  return (
    <AppBar position="sticky" color="secondary">
      <Toolbar>
        <BasicMenu />
        <Logo to="/app/welcome">
          <img style={{ height: 30 }} src={theme.getLogoSrc()} alt="logo" />
        </Logo>
        { isAuthenticated && <NotificationsButton count={unreadNotificationCount} /> }
        { isAuthenticated && <AccountButton /> }
      </Toolbar>
    </AppBar>
  );
}
