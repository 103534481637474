import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// Custom UI
import ProductCreateDetails from '../products/components/ProductCreateDetails';
import ActionBar from '../../components/ActionBar';

// Helpers
import { useFetch } from 'use-http';
import { FormProvider, useForm } from 'react-hook-form';

// Dialogs
import { useDialog } from "../../dialog/DialogProvider";
import Popup from '../../dialog/Popup';

export default function CustomerProductsCreatePage({ action = 'create' }) {

  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [product, setProduct] = useState();

  const { del, post, get, put, response } = useFetch('customer-products');

  const { handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
  });

  const [openDialog, closeDialog] = useDialog();

  useEffect(() => {
    async function initializeProduct() {
      if (params.customerProductId) {
        const product = await get(params.customerProductId);
        if (response.ok) {
          setProduct({
            ...product,
            wanted_products: product.wanted_products || []
          });
        }
      }
    }
    if (location.state?.product) {
      setProduct({
        ...location.state.product,
        wanted_products: location.state.product.wanted_products || []
      })
    } else {
      initializeProduct();
    }
  }, [setProduct, location, get, response, params]);

  const onOpenDialog = (details) => {
    openDialog({
      children: (
        <Popup details={details} />
      )
    });
  };

  const popupDeleteProduct = {
    buttonStyle: "contained",
    popupTitle: t('dialog_delete_item.title'),
    bodyText: t('dialog_delete_item.text'),
    proceedTitle: t('dialog_delete_item.btn_action'),
    cancelTitle: t('dialog_delete_item.btn_cancel'),
    showCancel: true,
    proceedHandler: () => {
      console.log("[CustomerProductsUpdate]: Deleting product", params.customerProductId);
      handleDeleteProduct();
      closeDialog();
    },
    closeHandler: () => {
      closeDialog();
    }
  }

  const onSubmit = async (data) => {
    const swapSize = data.swap_size;
    delete data.swap_size;

    const payload = {
      product_id: product.id,
      wanted_products: product.wanted_products,
      is_listed: true,
      ...data
    }

    console.log('[CustomerProductsUpdate]:', payload);

    const customerProduct = await put(product.id, payload);

    if (response.ok) {
      console.log('[CustomerProductsUpdate]: 1. Updated customer product', customerProduct)
      console.log('[CustomerProductsUpdate]: 2. Updated customer product', product)
      if (action === 'create') {
        console.log('[CustomerProductsUpdate]: go back');
        history.goBack();
      } else {
        console.log('[CustomerProductsUpdate]: confirm');
        history.push({
          pathname: '../create/confirm',
          state: { customerProduct, swapSize }
          //state: { product, swapSize }
        })
      }
    }
  }

  let actions = [];

  if (product && product.is_listed) {
    actions = [
      {
        primary: true,
        title: t('pdp_update.btn_update'),
        disabled: !isValid,
        submit: true
      },
      {
        title: t('pdp_update.btn_unlist'),
        onClick: () => {
          handleDelistProduct();
        }
      },
      {
        title: t('pdp_update.btn_back'),
        onClick: () => {
          history.goBack();
        }
      }
    ]
  } else if (product) {
    actions = [
      {
        primary: true,
        title: t('pdp_update.btn_list'),
        disabled: !isValid,
        submit: true
      },
      {
        title: t('pdp_update.btn_back'),
        onClick: () => {
          history.goBack();
        }
      }
    ]
  }

  const handleDelistProduct = async () => {
    console.log('[CustomerProductsUpdate]: Delisting product', params.customerProductId)
    await post(`${params.customerProductId}/delist`, {});

    if (response.ok) {
      console.log('[CustomerProductsUpdate]: Successfully delisted product', params.customerProductId);
      history.replace('/app/customer-products/create/list');
    }
  }

  const handleDeleteProduct = async () => {
    console.log('[CustomerProductsUpdate]: Deleting product',  params.customerProductId);
    await del(`${params.customerProductId}`);

    if (response.ok) {
      console.log('[CustomerProductsUpdate]: Successfully deleted product',  params.customerProductId);
      history.replace('/app/customer-products/create/list');
    }
  }

  const showDeleteProductDialog = () => {
    onOpenDialog(popupDeleteProduct);
  }

  return (
    <Container maxWidth="md" disableGutters>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {product && (
              <ProductCreateDetails product={product} control={control} errors={errors} />
            )}
            <ActionBar actions={actions} />
            <Box sx={{ textAlign: 'center'}}>
              <Link onClick={() => showDeleteProductDialog()} underline="always">{t('pdp_update.link_delete')}</Link>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
}