import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Context
import { useAuth } from '../../../context/AuthContext';
import { formatDistanceToNowStrict } from 'date-fns'

export default function TokenListItem({ token, onClick }) {

  const { t } = useTranslation();
  const { user } = useAuth();

  const getOfferStatusColor = (status) => {
    let color
    switch (status) {
      case 'open':
      case 'processing':
        color = 'secondary'
        break
      case 'redeemed':
        color = 'success'
        break
      case 'cancelled':
      case 'expired':
        color = 'error'
        break
      default:
        color = 'warning'
    }

    return color
  }

  return token && (
    <Box onClick={onClick} sx={{ p:2, cursor: "pointer", borderBottom: '1px solid #ccc' }}>
      <Box textAlign="right">
        <Chip color={getOfferStatusColor(token.status)} label={t(`token.text_chip_${token.status}`)} variant="outline" size="small"/>
      </Box>

      <Stack direction="row" spacing={2} sx={{ pt: 1, pb: 1 }}>
        <Stack direction="column" spacing={2}>
          <Typography variant="body1_bold">{token.ship_from.name}</Typography>
          <Typography variant="body1">{token.ship_from.address1}</Typography>
          <Typography variant="body1">{token.ship_from.zip}</Typography>
          {/* { token.tracking?.number && <Typography variant="body1">{token.tracking.number}</Typography> } */}
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center' }}><div>→</div></Box>
        <Stack direction="column" spacing={2}>
          <Typography variant="body1_bold">{token.ship_to.name}</Typography>
          <Typography variant="body1">{token.ship_to.address1}</Typography>
          <Typography variant="body1">{token.ship_to.zip}</Typography>
          {/* { token.tracking?.number && <Typography variant="body1">{token.tracking.number}</Typography> } */}
        </Stack>
      </Stack>
      <Typography variant="body1">{formatDistanceToNowStrict(token.created_at)}</Typography>
    </Box>
  )
}
