import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// MUI
import Container from '@mui/material/Container';

// Custom UI
import ProductCreateDetails from '../products/components/ProductCreateDetails';
import ActionBar from '../../components/ActionBar';

// Helpers
import { useFetch } from 'use-http';
import { FormProvider, useForm } from 'react-hook-form';

export default function CustomerProductsCreatePage({ type }) {

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [product, setProduct] = useState();

  const { get, response } = useFetch('customer-products');

  const { handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
  });


  const onSubmit = async (data) => {
    console.log('[CustomerProductsCreatePage] onSubmit:', data);
    const swapFilter = data.swap_size?.map(s => JSON.parse(s).id).filter(s => s !== 'any' || s !== '')

    let variant = product.variant
    if (product.variants) {
      variant = product.variants.find(v => v.name === data.variant_id)
      delete product.variants
    }

    const state = { customerProduct: { ...product, ...data, variant }}
    if (swapFilter.length > 0) {
      state.swapSize = swapFilter
    }

    window.scrollTo(0, 0)
    history.push({
      pathname: './confirm',
      state
    })
  }

  const actions = [
    {
      primary: true,
      title: 'List this',
      disabled: !isValid,
      submit: true
    }, {
      title: 'Cancel',
      onClick: () => {
        history.goBack()
      }
    }
  ]

  useEffect(() => {
    async function initializeProduct() {

      if (params.productId) {

        const product = await get(params.productId)

        if (response.ok) {
          console.log('Fetched product', product)
          setProduct(product);
        }
      }
    }

    if (location.state?.product) {
      setProduct(location.state.product)
    } else {
      initializeProduct()
    }
  }, [setProduct, location, get, params, response])

  return (
    <Container maxWidth="md" disableGutters>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          {product && <ProductCreateDetails product={product} control={control} errors={errors} />}
          <ActionBar actions={actions} />
        </form>
      </FormProvider>
    </Container>
  );
}
