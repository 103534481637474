import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

export default function Popup(props) {

  const { t } = useTranslation();

  const CentreItem = styled(Container)(({ theme }) => ({
    textAlign: 'center',
  }));

  const handleClose = () => {
    props.details.closeHandler();
  };

  const handleProceedAction = () => {
    props.details.proceedHandler();
  }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2, minWidth: "300px" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography component="p" variant='h2'>{props.details.popupTitle}</Typography>
        <Divider sx={{ pb: 2 }} />
      </DialogTitle>
      <CentreItem>
        <DialogContent>
          <DialogContentText>
            {props.details.headerText}
            {props.details.bodyText}
          </DialogContentText>
        </DialogContent>
      </CentreItem>
      <DialogActions>
        <Stack spacing={1} sx={{ flexGrow: 1, mb: 4 }}>
          <CentreItem>
            <Button sx={{ width: '100%' }} variant="contained" onClick={handleProceedAction} autoFocus>
              {props.details.proceedTitle}
            </Button>
          </CentreItem>
          { props.details.showCancel &&
            <CentreItem>
              <Button sx={{ width: '100%' }} variant="outlined" onClick={handleClose}>{ props.details.cancelTitle ? props.details.cancelTitle : t("dialog.btn_close") }</Button>
            </CentreItem>
          }
        </Stack>
      </DialogActions>
    </>
  );
}