import React from 'react';

// MUI
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

// Helpers
import { Controller } from 'react-hook-form';

export default function PhoneNumberForm({ title = 'Phone Number', phone = '', control, errors }) {

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={1}>
        <Typography variant="h2">{ title }</Typography>
        <Controller
          name="phone"
          control={control}
          defaultValue={phone}
          rules={{
            required: false
          }}
          render={({ field }) => <TextField
            {...field}
            required={false}
            type="text"
            placeholder="e.g. +447988123456"
            error={!!errors.phone}
          />}
        />
      </Stack>
    </Paper>
  )
}