import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Custom UI
import ProductCarousel from './ProductCarousel';
import MultiSelect from '../../../components/MultiSelect';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';

export default function ProductCreateDetails({ product, control, errors }) {

  const { t } = useTranslation();

  const reasonOptions = [
    { id: "reason_1", title: t('product_reason.reason_1') },
    { id: "reason_2", title: t('product_reason.reason_2') },
    { id: "reason_3", title: t('product_reason.reason_3') },
    { id: "reason_4", title: t('product_reason.reason_4') },
    { id: "reason_5", title: t('product_reason.reason_5') },
    { id: "reason_6", title: t('product_reason.reason_6') },
    { id: "reason_7", title: t('product_reason.reason_7') },
  ]

  const conditionOptions = [
    { id: "condition_1", title: t('product_condition.condition_1'), description: t('product_condition.condition_1_description') },
    { id: "condition_2", title: t('product_condition.condition_2'), description: t('product_condition.condition_2_description') },
    { id: "condition_3", title: t('product_condition.condition_3'), description: t('product_condition.condition_3_description') },
    { id: "condition_4", title: t('product_condition.condition_4'), description: t('product_condition.condition_4_description') },
    { id: "condition_5", title: t('product_condition.condition_5'), description: t('product_condition.condition_5_description')},
  ]

  let sizes;
  if (product.variants) {
    sizes = [
      ...product.variants.map(v => ({
        id: v.name,
        title: v.name
      }))
    ]
  } else {
    sizes = [{
      id: product.variant.id,
      title: product.variant.name
    }]
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Stack>
        <ProductCarousel product={product} />
        <Stack spacing={2} sx={{ p: 2 }}>
          { !product.customer_id && (<Select control={control} errors={errors} required={true} name="variant_id" label={t('pdp_create.label_size')} placeholder={t('pdp_create.placeholder_size')} options={sizes} defaultValue={product.customer_id ? product.variant.id : ''} />)}
          <Select control={control} errors={errors} required={true} name="reason" label={t('pdp_create.label_reason')} placeholder={t('pdp_create.placeholder_reason')} options={reasonOptions} defaultValue={product.reason} />
          <Select control={control} errors={errors} required={true} name="condition" label={t('pdp_create.label_condition')} placeholder={t('pdp_create.placeholder_condition')} options={conditionOptions} defaultValue={product.condition} />
          <TextField control={control} errors={errors} required={false} name="notes" label={t('pdp_create.label_information')} placeholder={t('pdp_create.placeholder_information')} multiline={true} defaultValue={product.notes} />

          {(<>
            <Divider />
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {t('page_product_create.text_swap_for')}
            </Typography>
            <MultiSelect
              control={control}
              errors={errors}
              required={false}
              name="swap_size"
              label={t('page_product_create.label_select_size')}
              placeholder={t('page_product_create.placeholder_search_size')}
              options={[
                {
                  id: 'any',
                  title: 'All sizes'
                },
                ...sizes
              ]}
            />
          </>)}
        </Stack>
      </Stack>
    </Container>
  )
}
