import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Custom UI
import ProductSwapDetails from '../products/components/ProductSwapDetails';
import ActionBar from '../../components/ActionBar';

// Helpers
import { useFetch } from 'use-http';
import { FormProvider, useForm } from 'react-hook-form';

export default function CustomerProductsDetailPage({ type }) {

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [product, setProduct] = useState();

  useEffect(() => {
    if (location.state?.product) {
      setProduct(location.state.product)
    }
  }, [setProduct, location])

  const { get, post, put, response } = useFetch('customer-products')

  const { handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    console.log('Product data', data)

    if (!product.customer_id) {
      const customerProduct = await post({
        product_id: product.id,
        is_listed: true,
        ...data
      })
      if (response.ok) {
        console.log('Saved new customer product', customerProduct)
        history.push({
          pathname: './wanting',
          state: { product }
        })
      }
    } else {
      const customerProduct = await put(product.id, {
        product_id: product.id,
        is_listed: true,
        ...data
      })
      if (response.ok) {
        console.log('Saved new customer product', customerProduct)
        history.push({
          pathname: './wanting',
          state: { product }
        })
      }
    }


  }

  const actions = [
    {
      title: 'Back',
      onClick: () => {
        history.goBack()
      }
    }, {
      primary: true,
      title: 'Add item',
      disabled: !isValid,
      submit: true
    }
  ]

  useEffect(() => {
    async function initializeProducts() {

      if (!product && params.productId) {

        const product = await get(params.productId)

        if (response.ok) {
          console.log('Fetched product', product)
          setProduct(product);
        }
      }
    }

    initializeProducts()
  }, [get, response, product, params.productId])

  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }}>
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            {product && <ProductSwapDetails product={product} control={control} errors={errors} />}
          </Box>
          <ActionBar actions={actions} />
        </Stack>
      </form>
    </FormProvider>
  );
}
