import React from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Assets
import PatchImage from "../images/Patch.svg";

export default function PageAlert({ message, severity, showPatch, children }) {

  return (
    <Container maxWidth="md">
      <Box sx={{ m: 4 }} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
        { showPatch && <img src={ PatchImage } alt="" /> }
        <Box sx={{mt: 2, p: 2 }} display="flex">
          { severity &&
            <Alert severity={severity} style={{ textAlign: 'center', backgroundColor: '#fff' }}>
              {message && <Typography align='center'>{message}</Typography>}
              {children}
            </Alert>
          }
        </Box>
      </Box>
    </Container>
  );
}