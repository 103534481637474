import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';

// Helpers
import { formatDistanceToNowStrict } from 'date-fns'

export default function NotificationRow({ notification, onClick }) {

  const details = JSON.parse(notification.details);


  return (
    <Container onClick={onClick}>

      <Paper sx={{ p: 2 }} elevation={3}>
        <Stack direction="row" spacing={2}>
          <FiberManualRecordIcon sx={{ pr:2,  mx: "auto" }} color={!notification.is_read ? "primary" : "secondary"} />
          <Stack spacing={2} sx={{ flex: 1 }}>
            <Typography variant={notification.is_read ? 'h2':'h2'}>{details.title}</Typography>
            <Typography variant="body1">{details.message}</Typography>
            <Typography variant="body2">{formatDistanceToNowStrict(notification.created_at)}</Typography>
          </Stack>
        </Stack>
      </Paper>

    </Container>
  );
}
