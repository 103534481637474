import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Custom UI
import ProductSwapDetails from '../products/components/ProductSwapDetails';
import ActionBar from '../../components/ActionBar';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';

// Helpers
import { useFetch } from 'use-http';
import { FormProvider, useForm } from 'react-hook-form';

export default function CustomerProductsDetailPage({ action = 'create' }) {

  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [wantedProduct, setWantedProduct] = useState();
  const [product, setProduct] = useState();

  useEffect(() => {
    if (location.state?.product) {
      setProduct(location.state.product)
    }
    if (location.state?.wantedProduct) {
      setWantedProduct(location.state.wantedProduct)
    }
  }, [setWantedProduct, location])

  const { get, response } = useFetch('customer-products')

  const { handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    console.log('Wanted product data', data, wantedProduct, product)

    if (!product.wanted_products) {
      product.wanted_products = []
    }

    const variant = wantedProduct.variants.find(v => v.id === data.variant_id)
    delete wantedProduct.variants
    product.wanted_products.push({
      ...wantedProduct,
      ...data,
      variant
    })

    if (!wantedProduct.customer_id) {
      // const customerProduct = await post({
      //   product_id: product.id,
      //   is_listed: true,
      //   ...data
      // })
      // if (response.ok) {
        // console.log('Saved new customer product', customerProduct)
        history.push({
          pathname: action === 'create' ? './confirm' : `../${product.id}`,
          state: { product }
        })
      // }
    } else {
      // const customerProduct = await put(product.id, {
      //   product_id: product.id,
      //   is_listed: true,
      // })
      // if (response.ok) {
        // console.log('Updated wanted product', customerProduct)
        history.push({
          pathname: action === 'create' ? './confirm' : `../${product.id}`,
          state: { product }
        })
      // }
    }


  }

  const actions = [
    {
      title: 'Back',
      onClick: () => {
        history.goBack()
      }
    }, {
      primary: true,
      title: action === 'create' ? 'Next' : 'Add' ,
      disabled: !isValid,
      submit: true
    }
  ]

  useEffect(() => {
    async function initializeProducts() {

      if (!wantedProduct && params.productId) {

        const product = await get(params.productId)

        if (response.ok) {
          console.log('Fetched product', product)
          setWantedProduct(product);
        }
      }
    }

    initializeProducts()
  }, [get, response, wantedProduct, params.productId])

  return (
    <PageContainer>
      <PageTitle>
        {t('page_create_confirm.text_title')}
      </PageTitle>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {wantedProduct && <ProductSwapDetails product={wantedProduct} control={control} errors={errors} />}
            </Box>
            <ActionBar actions={actions} />
          </Stack>
        </form>
      </FormProvider>
    </PageContainer>
  );
}
