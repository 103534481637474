import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Custom UI
import NotificationRow from './components/NotificationRow';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import Spinner from '../../components/Spinner';

// Helpers
import useFetch from 'use-http';

export default function Notifications() {

  const { t } = useTranslation();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { post, response } = useFetch('notifications');

  useEffect(() => {
    async function initializeNotifications() {
      const notifications = await post('search', {});
      if (response.ok) {
        setNotifications(notifications.records);
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    initializeNotifications();
  }, [post, response])

  const handleNotificationClick = async (notification) => {
    if (!notification.is_read) {
      await post(`${notification.id}/read`, {});
    }
    if (notification.type === 'OFFER') {
      history.push({
        pathname: `/app/offers/${notification.reference_id}`,
        state: { notification }
      });
    }
    if (notification.source.split(':')[0] === 'tokens') {
      history.push({
        pathname: `/app/tokens/${notification.reference_id}`,
        state: { notification }
      });
    }
  }

  return (
    <PageContainer>
      <PageTitle>
        {t('page_notifications.text_title')}
      </PageTitle>
      <Stack spacing={2}>

        { isLoading &&
          <Spinner />
        }

        { !isLoading && notifications.length && (notifications.map((notification) => (
            <NotificationRow key={notification.id} notification={notification} onClick={() => handleNotificationClick(notification)} />
          )))
        }

        { !isLoading && !notifications.length &&
          <Typography component="p" variant="p" align='center' sx={{ pt: 2 }}>{t('page_notifications.text_none')}</Typography>
        }

      </Stack>
    </PageContainer>
  );
}
