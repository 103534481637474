import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MUI
import Stack from '@mui/material/Stack';

// Custom UI
import ProductsSearch from '../products/components/ProductsSearch';
import ActionBar from '../../components/ActionBar';

export default function CustomerProductsWantingPage({ selectUrl = './wanting-detail' }) {

  const history = useHistory();
  const location = useLocation();

  const handleSelectProduct = (wantedProduct) => {
    console.log('Wanted product', wantedProduct)

    history.push({
      pathname: selectUrl,
      state: { product: location.state.product, wantedProduct }
    })
  }

  const actions = [
    {
      title: 'Cancel',
      onClick: () => {
        history.goBack()
      }
    }, {
      title: 'Skip',
      primary: true,
      onClick: () => {
        history.push({
          pathname: './confirm',
          state: { product: location.state.product }
        })
      }
    }
  ]

  return (
    <Stack>
      <ProductsSearch
        type="products"
        title={"What would you like to swap for?"}
        subTitle={"We will try to help find a swap for these items"}
        onProductClick={handleSelectProduct}
      />
      <ActionBar actions={actions} />
    </Stack>
  );
}
