import React from 'react';

// MUI
import Container from '@mui/material/Container';

export default function PageContainer({children}) {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {children}
    </Container>
  );
}
