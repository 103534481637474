import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Helpers
import useMappedValues from '../../../hooks/MappedValues';

const imageStyle = {
  width: '100%',
  border: '1px solid #ddd'
}

const DetailsWrapper = styled(Box)`
  display: flex;
  alignItems: center;
  justifyContent: space-between;
  width: 100%;
  gap: 2rem;
`

const ImageWrapper = styled(Box)`
  flex: 0 0 auto;
  width: 100%;
  max-width: 120px;
`

const ContentWrapper = styled(Box)`
  flex: 1 1 auto;
`

export default function ProductCard({ product, actions }) {

  const { t } = useTranslation();
  const { get } = useMappedValues();

  return (
    <Box>
      {product && (
        <DetailsWrapper>
          <ImageWrapper>
            <img alt={product.name} src={`${product.images[0]}?crop=center&height=200`} style={imageStyle} />
          </ImageWrapper>
          <ContentWrapper>
            <Stack spacing={2}>
              <Box>
                <Typography component="p" variant="p">{product.name}</Typography>
              </Box>
              <Box>
                {product.variant && (<Typography component="p" variant="body1_bold">{t("product_card.label_size")} {product.variant?.name}</Typography>)}
              </Box>
              <Box>
                {product.reason && <Typography component="p" variant="body2">{t("product_card.label_reason")}</Typography>}
                {product.reason && <Typography component="p" variant="p">{t(get('reason', product.reason))}</Typography>}
              </Box>
              <Box>
                {product.condition && <Typography component="p" variant="body2">{t("product_card.label_condition")}</Typography>}
                {product.condition && <Typography component="p" variant="p">{t(get('condition', product.condition))}</Typography>}
              </Box>
              <Box>
                {actions}
              </Box>
            </Stack>
          </ContentWrapper>
        </DetailsWrapper>
      )}
    </Box>
  );
}
