import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Helpers
import { Controller, FormProvider, useForm } from 'react-hook-form';

export default function AddressForm({ onSubmit, defaultValues = {}, loading = false }) {

  const { t } = useTranslation();
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues
  });

  return (
    <>
      <Typography variant="h2" sx={{mb:2}}>{t("page_account_update_name.text_title")}</Typography>
      <Paper sx={{ p: 2 }}>
        <FormProvider>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1}>
              <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                <Controller
                  name="first_name"
                  control={control}
                  def
                  render={({ field }) => <TextField
                    {...field}
                    required={true}
                    type="text"
                    placeholder={t("page_account_update_name.label_first")}
                    error={!!errors.phone}
                  />}
                />
                <Controller
                  name="last_name"
                  control={control}
                  def
                  render={({ field }) => <TextField
                    {...field}
                    required={true}
                    type="text"
                    placeholder={t("page_account_update_name.label_last")}
                    error={!!errors.phone}
                  />}
                />
              </Stack>
              <Box sx={{pt:2}}>
                <LoadingButton loading={loading} type="submit" variant="contained">{t("page_account_update_name.btn_save")}</LoadingButton>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </Paper>
    </>
  )
}