import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Custom UI
import Spinner from '@/components/Spinner';
import Alert from '@/components/Alert';

// Helpers
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Context
import { useAuth } from '@/context/AuthContext';
import { useTheme } from '@/theme/ThemeContext';

export default function LoginFields() {

  const auth = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleForgottenPassword = () => {
    history.push({
      pathname: './forgotten-password'
    })
  }

  const { handleSubmit, control, register, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = async (params) => {
    setIsAuthenticating(true);
    try {
      const authenticated = await auth.login(params);
      if (authenticated) {
        setRedirectToReferrer(true);
      } else {
        setIsAuthenticating(false);
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
      setIsAuthenticating(false);
      console.log('Failed auth', error);
    }
  }

  if (redirectToReferrer) {
    console.log('Redirecting')
    return <Redirect to="/" />
  }

  return (
    <Container maxWidth="sm">
      <FormProvider>
        { isAuthenticating ? <Spinner /> :
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" id="shop" name="shop" value={theme.settings.shop} {...register('shop')} />
            <Stack spacing={1}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <OutlinedInput
                  {...field}
                  required={true}
                  type="email"
                  placeholder={t('page_login.field_email_label', { brand: theme.getBrandName() })}
                  error={!!errors.email}
                  variant="outlined"
                />}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    required={true}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('page_login.field_password_label')}
                    error={!!errors.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <Button type="submit" variant="contained" fullWidth>{t('page_login.btn_signin')}</Button>
              <Button fullWidth variant="outlined" onClick={() => { handleForgottenPassword() }}>{t('page_login.btn_forgotten')}</Button>
            </Stack>
          </form>
        }
      </FormProvider>
      { hasError && !isAuthenticating &&
        <Alert severity="warning" showPatch={false} message={t('page_login.text_error')} />
      }
    </Container>
  );
}
